import { ref, reactive, computed, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';
import { formatISO, startOfDay } from 'date-fns';

import { productTypes } from '@/constants';
import { useApi } from '@/composables/useApi';
import useAlertStore from '@/stores/alerts';

export default defineStore('agent/domains', () => {
	const VueRoute = useRoute();

	const saveStatus = ref('hide');
	const saveError = ref(null);
	const saveMessage = ref(null);
	const saveDomain = ref(null);
	const progress = reactive({
		routes: 0,
		domains: 0,
	});

	const domains = ref(null);
	const whois = ref(null);
	const domainDashboardGraph = ref(null);
	const domainDashboardTotal = ref(null);
	const domainM1Stats = ref({});
	const domainProxyStats = ref({});
	const saveNewProductType = ref(null);
	const movableDomains = ref(null);

	const parkedTargets = computed(() => {
		return (
			domains.value?.filter(
				domain =>
					domain.is_cancelled === false &&
					['sf_microsite', 'm1', 'm2'].includes(domain.product_type)
			) ?? []
		);
	});
	async function setProductType(associate_id, domain_name, product_type) {
		saveNewProductType.value = product_type;
		saveDomain.value = domain_name;
		saveStatus.value = 'saving';
		try {
			saveMessage.value = `Converting ${domain_name} to ${productTypes[product_type].titleShort}`;
			await useApi(`api/agents/${associate_id}/domains/${domain_name}/`, {
				message:
					'We are unable to change your domain at this time! Please try again later.',
			}).put({ product_type });

			await getDomains();
			// await editorStore.updateRoutes();
			saveStatus.value = 'saved';
		} catch (error) {
			saveStatus.value = 'error';
			saveError.value = error;
			return Promise.reject(error);
		} finally {
			setTimeout(async () => {
				saveStatus.value = 'hide';
			}, 7000);
		}
	}

	async function getDomains() {
		try {
			const { agentID } = VueRoute.params;
			if (!agentID) {
				return Promise.reject(
					new ReferenceError('Agent ID is required for this request but is undefined')
				);
			}
			const fetcher = useApi(`api/agents/${VueRoute.params.agentID}/domains/`, {
				message: 'There was an issue retrieving your domains! Please try again later.',
			});

			watch(
				() => fetcher.progress,
				newValue => {
					progress.domains = newValue;
				},
				{ immediate: true }
			);

			const { data } = await fetcher.json();
			domains.value = data.value;

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getWhois({ domainName }) {
		whois.value = null;
		const res = await useApi(
			`api/agents/${VueRoute.params.agentID}/domains/${domainName}/whois/`,
			{
				message: 'There was an issue retrieving your domain name! Please try again later.',
			}
		).json();
		whois.value = res.data?.value;
	}
	async function ensureDomains() {
		if (domains.value === null) {
			await getDomains();
		}
	}
	async function getRoutes(domainName) {
		await ensureDomains();
		let domain = domains.value?.find(domain => domain.domain_name === domainName);
		if (!domain) {
			return [];
		}
		if (domain.routes) {
			return domain.routes;
		} else {
			const fetcher = useApi(
				`api/agents/${VueRoute.params.agentID}/domains/${domainName}/routes/?${new URLSearchParams(
					{
						details: 'simple',
					}
				)}`,
				{
					message: `The content for ${domainName} did not load properly`,
				}
			);

			watch(
				() => fetcher.progress,
				newValue => {
					progress.routes = newValue;
				},
				{ immediate: true }
			);

			const { data } = await fetcher.json();
			domain.routes = data.value;
			return domain?.routes ?? [];
		}
	}
	async function getDashboardGraph({ start, end }) {
		try {
			const result = await useApi(
				`api/agents/${VueRoute.params.agentID}/stats/account_overview/?${new URLSearchParams(
					{
						start: formatISO(start).substring(0, 19),
						end: formatISO(end).substring(0, 19),
					}
				)}`,
				{
					message:
						'There was an issue retrieving your graph data from the server! Please try again later.',
				}
			).json();

			domainDashboardGraph.value = result?.data?.value?.graph;
			domainDashboardTotal.value = result?.data?.value?.total;
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function ensureDashboardGraph({ start, end }) {
		if (!domainDashboardGraph.value) {
			await getDashboardGraph({ start, end });
		}
		return Promise.resolve();
	}
	async function ensureDashboardTotal({ start, end }) {
		if (!domainDashboardTotal.value) {
			await getDashboardGraph({ start, end });
		}
		return Promise.resolve();
	}

	async function cancelDomain({ domainName }) {
		try {
			await useApi(`api/agents/${VueRoute.params.agentID}/domains/${domainName}/`, {
				message: 'There was an issue canceling your domain name! Please try again later.',
			}).delete();

			// reload();
			await getDomains();

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function transferDomain({ name, product_type, auth_code }) {
		await useApi(`api/agents/${VueRoute.params.agentID}/domains/`, {
			message: `Sorry, there was an issue transferring ${name}.`,
		}).post({
			name,
			product_type,
			is_transfer: true,
			auth_code,
		});
	}

	async function getDomainM1Stats({ domain_name, start, end }) {
		const start_string = formatISO(startOfDay(start)).substring(0, 19);
		const end_string = formatISO(startOfDay(end)).substring(0, 19);

		const current_bundle = domainM1Stats.value[domain_name];

		if (
			current_bundle &&
			start_string === current_bundle.start &&
			end_string === current_bundle.end
		) {
			return;
		}
		domainM1Stats.value[domain_name] = null;

		const { data } = await useApi(
			`api/domains/${domain_name}/stats/domain_analysis/?${new URLSearchParams({
				start: start_string,
				end: end_string,
			})}`,
			{
				message:
					'There was an issue retrieving your domain M1 stats! Please try again later.',
			}
		).json();

		const bundle = {
			start: start_string,
			end: end_string,
			stats: data.value,
		};

		domainM1Stats.value[domain_name] = { ...bundle };
	}
	async function getDomainProxyStats({ domain_name, start, end }) {
		const startString = formatISO(startOfDay(start)).substring(0, 19);
		const endString = formatISO(startOfDay(end)).substring(0, 19);

		const currentBundle = domainProxyStats.value[domain_name];

		if (
			currentBundle &&
			startString === currentBundle.start &&
			endString === currentBundle.end
		) {
			return;
		}

		domainProxyStats.value[domain_name] = null;

		const { data } = await useApi(
			`api/domains/${domain_name}/stats/domain_overview/?${new URLSearchParams({
				start: startString,
				end: endString,
			})}`,
			{
				message:
					'There was an issue retrieving your domain proxy data! Please try again later.',
			}
		).json();

		const bundle = {
			start: startString,
			end: endString,
			stats: data.value,
		};
		domainProxyStats.value[domain_name] = { ...bundle };
	}

	async function getMovableDomains() {
		const { data } = await useApi(`api/agents/${VueRoute.params.agentID}/domains/movables/`, {
			message: `The content for movable domains did not load properly`,
		}).json();

		movableDomains.value = data.value; //JSON.parse(data.value);
	}

	async function moveDomain({ name, target_alias, target_product_type, remove_redirects }) {
		const { statusCode, error } = await useApi(
			`api/agents/${VueRoute.params.agentID}/domains/${name}/move/`,
			{
				message: 'There was an issue moving your domain. Please try again later.',
			}
		)
			.put({
				target_alias,
				target_product_type,
				remove_redirects,
			})
			.json();
		if (statusCode.value === 400) {
			return Promise.reject(new Error(error.value.message));
		}

		return null;
	}

	async function changeQLPPreference(domainName, preference) {
		try {
			await useApi(
				`api/agents/${VueRoute.params.agentID}/domains/${domainName}/change_single_qlp/`,
				{
					message:
						'There was an issue changing your qlp preferences. Please try again later.',
				}
			).post({ preference });

			await getDomains();
		} catch (error) {
			const alertStore = useAlertStore();

			alertStore.addAlert({
				message:
					"Uh oh, we couldn't get your stuff from the server! Please try again later",
			});
			return Promise.reject(error);
		}
	}

	async function onReset() {
		progress.domains = 0;
		progress.routes = 0;

		await nextTick();
		return Promise.resolve();
	}

	return {
		domains,
		whois,
		saveStatus,
		saveError,
		saveMessage,
		saveDomain,
		saveNewProductType,

		parkedTargets,
		domainDashboardTotal,
		domainDashboardGraph,
		progress,

		domainM1Stats,
		domainProxyStats,
		movableDomains,

		setProductType,

		getDomains,
		getRoutes,
		getDashboardGraph,
		getWhois,
		getDomainM1Stats,
		getDomainProxyStats,

		transferDomain,
		cancelDomain,

		ensureDomains,
		ensureDashboardTotal,
		ensureDashboardGraph,

		getMovableDomains,
		moveDomain,

		changeQLPPreference,

		onReset,
	};
});
