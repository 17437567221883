import { watch, ref, reactive, computed, nextTick, toRaw } from 'vue';
import { defineStore } from 'pinia';
import { useLocalStorage, StorageSerializers } from '@vueuse/core';
import { useRoute } from 'vue-router';

import { differenceInHours, formatISO, parseISO } from 'date-fns';
import { isObject } from 'lodash';

import { useApi } from '@/composables/useApi';

import useEditorChanges from '@/stores/editorChanges';

/**
 * @typedef EditorStoreType
 * @type { actions | getters | state | import('pinia').Store }
 */
/**
 * @typedef useEditorStore
 * @type function
 * @returns EditorStoreType
 */

export default defineStore('editor', () => {
	const VueRoute = useRoute();
	// local editor state
	const initializing = ref(true);
	const savingChanges = ref(false);
	const abortFunctions = reactive({
		config: {},
		routes: {},
		overrides: {},
	});

	// current state pulled from api
	const overrides = ref([]);
	const productType = ref(null);
	const pages = ref({});
	const routes = ref([]);

	const progress = reactive({
		routes: 0,
		config: 0,
		overrides: 0,
	});
	const core = ref({});
	const articleBlacklist = ref([]);

	const pendingOverrides = computed(() =>
		overrides.value.filter(override => override.status === 'pending')
	);
	const approvedOverrides = computed(() =>
		overrides.value.filter(override => override.status === 'approved')
	);

	function getRouteByUniqueKey(unique_key) {
		let final = {};
		const mainRoute = structuredClone(
			toRaw(routes.value.find(route => route.unique_key === unique_key) || {})
		);
		if (mainRoute) {
			final = mainRoute;
			const relatedRoutes = routes.value.filter(
				route =>
					route.route_class === mainRoute.route_class &&
					route.unique_key !== mainRoute.unique_key
			);
			if (relatedRoutes?.length > 0) {
				final.relatedRoutes = relatedRoutes;
			}
		}

		final.routePath = final.paths?.[0];

		final.overrides = overrides.value?.filter(
			override => override.route === unique_key && override.status === 'approved'
		);

		if (Array.isArray(final.context)) {
			final.context = { listings: final.context };
		}
		return final;
	}

	const social = computed(() => {
		let sm = core.value?.agent?.social;

		if (!sm) {
			return {};
		}
		if (Array.isArray(sm)) {
			sm = Object.fromEntries(sm.map(s => [s.site?.toLowerCase(), s]));
		}
		return sm;
	});

	const office = computed(() => core.value.office ?? {});

	const agent = computed(() => core.value?.agent ?? {});

	const feeds = computed(() => core.value?.feeds);

	const qlp = computed(() => core.value?.qlp ?? {});

	// HOUSEKEEPING: is this ever used? why is it using core.feeds?
	const meta = computed(() => core.value?.feeds ?? {});

	const preferredPhone = computed(() => {
		const office = core.value?.office ?? {};
		return (
			office.preferred_phone ||
			(office.phone?.business || [])[0] ||
			office.phone?.on_microsite?.phone ||
			null
		);
	});
	const teamMembers = computed(() => {
		const members = core.value?.office?.team?.members ?? [];

		return structuredClone(toRaw(members)).map(m => {
			// image
			if (isObject(m.image)) {
				m.image = m.image.large;
			}
			m.eligible_for_display = m.on_microsite || false;
			return m;
		});
	});

	async function updateConfig(domainName = undefined) {
		if (domainName === undefined) {
			domainName = VueRoute?.params?.domainName;
		}

		const fetcher = useApi(`api/domain/${domainName}/config/?include_pages=false`, {
			message: `The content for ${domainName} did not load properly`,
		});
		abortFunctions.config = { abort: fetcher.abort, canAbort: fetcher.canAbort };

		watch(
			() => fetcher.progress,
			newValue => {
				progress.config = newValue;
			},
			{ immediate: true }
		);

		const { data } = await fetcher.json();
		if (data.value) {
			core.value = data.value.core;
			pages.value = data.value.pages;
			productType.value = data.value.productType;

			abortFunctions.config = {};
			await updateOverrides(domainName);
		}
	}

	async function updateOverrides(domainName) {
		if (domainName === undefined) {
			domainName = VueRoute?.params?.domainName;
		}
		const overridesFetcher = useApi(
			`api/domains/${domainName}/overrides/?status=pending&status=approved`,
			{
				message: `The check for pending content overrides failed.`,
			}
		);

		abortFunctions.overrides = {
			abort: overridesFetcher.abort,
			canAbort: overridesFetcher.canAbort,
		};

		watch(
			() => overridesFetcher.progress,
			newValue => {
				progress.overrides = newValue;
			},
			{ immediate: true }
		);

		const { data: overridesData } = await overridesFetcher.json();
		overrides.value = overridesData.value;

		abortFunctions.overrides = {};
	}

	async function updateRoutes() {
		const domainName = VueRoute?.params?.domainName;

		const fetcher = useApi(`api/domains/${domainName}/routes/`, {
			message: `The content for ${domainName} did not load properly`,
		});

		const { abort, canAbort } = fetcher;
		abortFunctions.routes = { abort, canAbort };
		watch(
			() => fetcher.progress,
			newValue => {
				progress.routes = newValue;
			},
			{ immediate: true }
		);

		const { data } = await fetcher.json();
		if (fetcher.error?.value) {
			throw fetcher.error.value;
		}

		routes.value = data.value;

		abortFunctions.routes = {};
		return routes.value;
	}

	async function updateEditorVisits() {
		const domainName = VueRoute?.params?.domainName;
		const visits = useLocalStorage('editor_visits', null, {
			serializer: StorageSerializers.object,
			mergeDefaults: true,
		});
		const visitForDomainName = visits.value?.[domainName];
		const lastVisit = visitForDomainName ? parseISO(visitForDomainName) : null;
		const currentVisit = new Date();
		const difference = differenceInHours(currentVisit, lastVisit, {
			roundingMethod: 'round',
		});
		if (!lastVisit || difference > 4) {
			await useApi(`api/mxeditor/${domainName}/log_editing/`, {
				message: `An editor api has failed`,
			}).put();
			visits.value = { ...visits.value, [domainName]: formatISO(currentVisit) };
		}
	}

	async function initializeEditor() {
		initializing.value = true;
		const domainName = VueRoute?.params?.domainName;
		await this.$reset();

		if (!domainName) {
			return Promise.reject('No domain name available to initialize editor');
		}
		const editorChangesStore = useEditorChanges();
		// updateConfig sets productType & agentID, which are needed for updateRoutes
		await updateConfig();
		await updateRoutes();
		await updateEditorVisits();
		await editorChangesStore.$reset();
		initializing.value = false;
		return Promise.resolve(`editor initialized for ${domainName}`);
	}

	async function getArticleBlacklist() {
		const domainName = VueRoute?.params?.domainName;

		const { data } = await useApi(`api/domains/${domainName}/article_blacklist/`, {
			message: `The content for ${domainName} did not load properly`,
		}).json();

		articleBlacklist.value = data.value || [];
		return Promise.resolve();
	}

	async function onReset() {
		await Object.values(abortFunctions).forEach(abortFunction => {
			const {
				abort = null,
				// canAbort = false,
			} = abortFunction;

			// canAbort does not currently work:
			// https://github.com/vueuse/vueuse/pull/3014
			// if (canAbort) {
			abort?.();
			// }
		});

		progress.routes = 0;
		progress.config = 0;
		progress.overrides = 0;

		await nextTick();
		return Promise.resolve();
	}
	return {
		core,
		initializing,

		savingChanges,

		overrides,
		pendingOverrides,
		approvedOverrides,

		pages,
		routes,
		progress,
		articleBlacklist,
		productType,

		//getters
		getRouteByUniqueKey,
		social,
		office,
		agent,
		feeds,
		meta,
		preferredPhone,
		teamMembers,
		qlp,

		// actions
		updateConfig,
		updateEditorVisits,
		updateRoutes,
		updateOverrides,
		initializeEditor,
		getArticleBlacklist,

		// needed for unit tests
		abortFunctions,
		onReset,
	};
});
