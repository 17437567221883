import useUserStore from '@/stores/user';

export default [
	{
		path: 'calltracking/',
		name: 'OverviewCalltracking',
		component: () => import('@/views/Overview/CallTracking'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'calltracking',
			title: 'Call Tracking',
		},
	},
	{
		path: 'qlp/',
		name: 'AQLP',
		component: () => import('@/views/Overview/AQLP'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'qlp',
			label: 'AQLP',
			title: `Agent Quote Landing Page Stats`,
		},
	},
	{
		path: 'queue/',
		name: 'queue',
		component: () => import('@/views/Overview/ApprovalQueue'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'queue',
			title: 'Approval Queue',
		},
	},
	{
		path: 'm1stats/',
		name: 'm1stats',
		redirect: { path: 'stats/' },
	},
	{
		path: 'stats/',
		name: 'stats',
		component: () => import('@/views/Overview/OverviewStats'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'm1stats',
			title: 'Overview Stats',
		},
	},
	{
		path: 'participation/',
		name: 'participation',
		component: () => import('@/views/Overview/ParticipationSearch'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,

			perm: 'participation',
			label: 'Participation',
			title: 'Participation Search',
		},
	},
	{
		path: 'departures/',
		name: 'departures',
		component: () => import('@/views/Overview/DepartureList'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'departures',
			label: 'Departures',
			title: 'Departures',
		},
	},
	{
		path: 'reports/',
		name: 'reports',
		component: () => import('@/views/Overview/ReportsView'),
		beforeEnter: getAllowedOverviewPages,
		meta: {
			auth: true,
			perm: 'reports',
			label: 'Reports',
			title: 'Reports',
		},
	},
];
async function getAllowedOverviewPages(to, from, next) {
	const userStore = useUserStore();
	if (userStore.user === null) {
		await userStore.getUserData();
	}

	const perm = to.meta.perm;
	if (userStore.user.corporate_permissions.includes(perm)) {
		next();
	} else {
		localStorage.setItem('accessDeniedPath', to.path);
		next('/forbidden');
	}
}
