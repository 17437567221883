import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export default defineStore('support', () => {
	const hostTarget = ref(
		['development'].includes(process.env.NODE_ENV)
			? useLocalStorage('__MYSFDOMAIN_UI_HOST__', 'mysfdomain.local')
			: ''
	);

	const warnAboutProd = computed(() => hostTarget.value === 'mysfdomain');
	const host = computed(() => {
		switch (process.env.NODE_ENV) {
			case 'test':
				//happy-dom/msw combination requires absolute urls
				return 'https://mysfdomain.com/';
			case 'development':
				return `/${hostTarget.value}/`;
			default:
				return '/';
		}
	});
	const devMode = computed(() => [import.meta.env, process.env.NODE_ENV].includes('development'));
	const testMode = computed(() => [import.meta.env, process.env.NODE_ENV].includes('test'));

	return {
		devMode,
		testMode,

		warnAboutProd,

		host,
		hostTarget,
	};
});
