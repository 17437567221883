<template>
	<div class="flex flex-col items-center gap-2">
		<div class="spinner-container">
			<svg class="spinner" :class="small ? 'w-6 h-6' : 'w-8 h-8'" viewBox="0 0 50 50">
				<circle :class="[spinnerColor]" cx="25" cy="25" r="20" />
			</svg>
		</div>
		<div v-if="text" class="font-semibold animate-pulse">{{ text }}</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	text: { type: String },
	small: { type: Boolean, default: false },
	gray: { type: Boolean, default: false },
	blue: { type: Boolean, default: false },
});

const spinnerColor = computed(() => {
	if (props.gray) {
		return 'stroke-gray-500';
	}
	if (props.blue) {
		return 'stroke-blue-700';
	}
	return 'stroke-orange-500';
});
</script>

<style lang="scss">
.spinner {
	animation: rotate 2s linear infinite;
}

.spinner circle {
	fill: none;
	stroke-width: 7;
	stroke-linecap: round;
	animation: dash 1.5s ease-in-out infinite;
	transform-origin: 50% 50%;
}

/* Rotate the entire spinner */
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

/* Create the dasharray effect */
@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: -125;
	}
}
</style>
