<template>
	<SortableTable
		:headers="headers"
		:items="items.map(item => ({ content: item }))"
		:title="itemName"
		item-unique-key="content"
		show-pagination
		show-title
		class="mt-5 rounded-md"
	>
		<template #item.content="{ content }">
			<div class="!whitespace-normal">{{ content }}</div>
		</template>

		<template #item.actions="item">
			<FAIcon
				icon="pencil"
				class="mr-2 text-lg text-orange hover:cursor-pointer"
				@click="editItem(item.content)"
			/>
			<FAIcon
				icon="trash"
				class="ml-2 text-lg text-red hover:cursor-pointer"
				@click="deleteItem(item.content)"
			/>
		</template>

		<template #toolbar>
			<BaseDialog v-model="dialog" max-width="600">
				<template #activator="{ openDialog }">
					<BaseButton
						:disabled="disabled"
						color="primary"
						class="mb-2"
						@click="openDialog"
					>
						New {{ itemName }}
					</BaseButton>
				</template>
				<template #header>
					<h3>{{ editedIndex === -1 ? 'New' : 'Edit' }} {{ itemName }}</h3>
				</template>

				<BaseTextArea
					v-model="editedItem"
					autofocus
					placeholder="item content"
					:label="`${itemName} content`"
					show-placeholder
				/>

				<template #actions>
					<BaseButton color="blue" dense @click="initialize"> Cancel </BaseButton>
					<BaseButton color="green" dense @click="save"> Save </BaseButton>
				</template>
			</BaseDialog>
			<BaseDialog v-model="deleteDialog" max-width="600" @close="closeDelete">
				<template #header>
					<h3>Are you sure you want to delete this {{ itemName.toLowerCase() }}?</h3>
				</template>
				<div
					class="rounded-lg border-l-4 border-gray-500 bg-gray-100 p-4"
					v-html="editedItem"
				/>
				<template #actions>
					<BaseButton color="blue" dense @click="closeDelete">Cancel</BaseButton>
					<BaseButton color="green" dense @click="deleteItemConfirm">OK</BaseButton>
				</template>
			</BaseDialog>
		</template>
	</SortableTable>
</template>

<script setup>
import { ref, nextTick } from 'vue';

import BaseButton from '@/components/ui/BaseButton';
import BaseTextArea from '@/components/ui/BaseTextArea';
import SortableTable from '@/components/ui/SortableTable.vue';

import BaseDialog from '@/components/ui/BaseDialog';

const headers = [
	{ sortable: true, text: 'Content', value: 'content', filterable: true },
	{ sortable: false, text: 'Actions', value: 'actions' },
];
const emit = defineEmits(['update:items', 'input']);

const props = defineProps({
	itemName: { type: String, default: 'Item' },
	items: { type: Array, required: true },
	disabled: { type: Boolean },
});

const dialog = ref(false);
const deleteDialog = ref(false);
const editedIndex = ref(-1);
const editedItem = ref(``);
const localItems = ref(props.items);

function initialize() {
	deleteDialog.value = false;
	dialog.value = false;
	localItems.value = props.items;
}

function editItem(item) {
	editedIndex.value = localItems.value.indexOf(item);
	editedItem.value = item;
	dialog.value = true;
}

function deleteItem(item) {
	editedIndex.value = localItems.value.indexOf(item);
	editedItem.value = item;
	deleteDialog.value = true;
}

function deleteItemConfirm() {
	localItems.value = localItems.value.filter(item => item !== editedItem.value);
	closeDelete();
}

function close() {
	dialog.value = false;
	editedIndex.value = -1;
	nextTick(() => {
		emit('input', localItems.value);
	});
}

function closeDelete() {
	deleteDialog.value = false;
	nextTick(() => {
		editedItem.value = ``;
		editedIndex.value = -1;
		emit('input', localItems.value);
	});
}

function save() {
	if (editedIndex.value > -1) {
		localItems.value[editedIndex.value] = editedItem.value;
	} else {
		localItems.value = [...localItems.value, editedItem.value];
	}
	close();
}
</script>

<style scoped></style>
