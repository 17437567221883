<template>
	<div class="whitespace-normal rounded p-4 text-left">
		<p v-if="loadProgress !== 100" class="w-full text-center text-xl text-orange">
			Loading content: {{ loadProgress }}%
			<ProgressBar :value="loadProgress" :max="100" class="mx-auto mt-2 w-1/2" />
		</p>

		<dl v-else-if="keywordDetail" class="flex flex-col gap-2">
			<dt v-if="dialog" class="-my-1 text-2xl font-medium text-black dark:text-gray-100">
				{{ keywordDetail.title }}
			</dt>

			<dd class="flex flex-col gap-4">
				<p class="!m-0" v-html="keywordDetail.description" />
				<template v-if="hasExamples">
					<div
						v-for="example in keywordDetail.examples"
						:key="example + 'new'"
						class="flex items-start rounded bg-orange-300 p-4 text-gray-900"
					>
						<FAIcon class="mr-4 self-center text-xl" icon="lightbulb" />
						<p class="!-my-1">Example: <span v-html="example" /></p>
					</div>
				</template>
				<template v-if="hasNotes">
					<div
						v-for="note in keywordDetail.notes"
						:key="note"
						class="flex items-center rounded bg-gray-300 p-4 text-gray-900"
					>
						<FAIcon class="mr-4 self-center text-xl" icon="info-circle" />
						<p class="!-my-1">Note: <span v-html="note" /></p>
					</div>
				</template>
			</dd>
			<div v-if="dialog" class="mt-2 flex items-center justify-end gap-2">
				<BaseButton :to="{ name: 'glossary' }" color="gray" text>View Glossary</BaseButton>
				<BaseButton color="red" @click="$emit('close')">Close</BaseButton>
			</div>
		</dl>
		<div v-else>
			<p class="text-lg text-black">
				<span>No keyword content was found for</span>
				<code>{{ keyword }}</code>
				<span>.Please check the spelling.</span>
			</p>
			<AppLink :to="{ name: 'glossary' }">View all glossary terms.</AppLink>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';

import { useGlossaryStore } from '@/stores/glossary';
import BaseButton from '@/components/ui/BaseButton';
import AppLink from '@/components/ui/AppLink.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const props = defineProps({
	keyword: { type: String, required: true },
	preview: { type: Object, default: null },
	dialog: { type: Boolean },
});

const glossaryStore = useGlossaryStore();
const { loadProgress } = storeToRefs(glossaryStore);

defineEmits(['close']);

const keywordDetail = computed(() => {
	return props.preview || glossaryStore.getKeywordDetails(props.keyword);
});
const hasExamples = computed(() => keywordDetail.value?.examples?.length > 0);
const hasNotes = computed(() => keywordDetail.value?.notes?.length > 0);

onMounted(glossaryStore.ensureKeywords);
</script>
