import { createPinia } from 'pinia';
import { toRaw, unref } from 'vue';

const pinia = createPinia();

export function toRawDeep(observed) {
	const val = toRaw(unref(observed));
	if (Array.isArray(val)) {
		return val.map(toRawDeep);
	}

	if (val === null) {
		return null;
	}

	if (typeof val === 'object') {
		return Object.fromEntries(Object.entries(val).map(([key, val]) => [key, toRawDeep(val)]));
	}

	return val;
}

export function patch$resetFunction({ store }) {
	const initialState = structuredClone(toRawDeep(store.$state));
	const initialKeys = Object.keys(initialState);

	store.$reset = async () => {
		await store.$patch($state => {
			Object.keys($state).forEach(key => {
				if (initialKeys.includes(key)) {
					$state[key] = toRawDeep(initialState[key]);
				} else {
					delete $state[key];
				}
			});
		});
		await store.onReset?.();
	};
}

pinia.use(patch$resetFunction);

export default pinia;
