import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { reportingObserverIntegration } from '@sentry/integrations';
import VueAnnouncer from '@vue-a11y/announcer';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import useAppVersionStore from './stores/appVersion';
import router from '@/router/router';
import pinia from '@/plugins/pinia';
import '@/styles/fontAwesome';
import '@/styles/index.css';
import { setupValidation } from '@/validationSetup';
import App from '@/App.vue';

const app = createApp(App)
	.use(pinia)
	.use(router)
	.use(VueAnnouncer)
	.component('FAIcon', FontAwesomeIcon);
app.mount('#app');

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		app,
		dsn: 'https://a059ccf0c5ec4a0499187e2de69da67f@o198392.ingest.us.sentry.io/1424454',
		trackComponents: true,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			reportingObserverIntegration({ types: ['crash', 'deprecation', 'intervention'] }),
		],
		tracesSampleRate: 0.5,
		tracePropagationTargets: [/^https:\/\/(\w*)(\.?)mysfdomain\.com/],
	});
}

setupValidation();

if (process.env.NODE_ENV === 'production') {
	const appVersionStore = useAppVersionStore(pinia);
	appVersionStore.periodicallyCheckForNewVersion();

	// to use a store outside a component, we need to pass the pinia object in
	router.afterEach(() => {
		if (appVersionStore.foundNewVersion) {
			appVersionStore.updateApp();
		}
	});
}
