import { reactive, ref } from 'vue';
import { createFetch } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';

import useAlertStore from '@/stores/alerts';
import useSupportStore from '@/stores/support';

export function useApi(url, args = {}) {
	const progress = ref(0);

	const receivedLength = ref(0);
	const contentLength = ref(0);
	const response = ref(undefined);

	const { message = `There was an issue fetching data.`, alertOnFailure = true } = args;
	const supportStore = useSupportStore();

	const baseUrl = `${supportStore.host}`;

	// hijack fetch to monitor progress for get requests in browser only
	async function customFetch(url, { ...currentArgs }) {
		if (currentArgs?.method !== 'GET' || import.meta.env === 'test ') {
			try {
				return await fetch(url, { ...currentArgs });
			} catch (error) {
				console.error(error);
				return error;
			}
		}
		return new Promise((resolve, reject) => {
			// Step 1: start the fetch and obtain a reader
			fetch(url, { ...currentArgs })
				.then(res => {
					contentLength.value = +res.headers.get('Content-Length');
					response.value = res;
					return res.body;
				})
				.then(rb => {
					const reader = rb?.getReader?.();

					return new ReadableStream({
						start(controller) {
							// The following function handles each data chunk
							function push() {
								if (contentLength.value === 0) {
									progress.value = 0;
								} else {
									const final = Math.round(
										(receivedLength.value / contentLength.value) * 100
									);
									progress.value = final;
								}
								// "done" is a Boolean and value a "Uint8Array"
								reader.read().then(({ done, value }) => {
									receivedLength.value += value?.length || 0;

									// If there is no more data to read
									if (done) {
										progress.value = 100;
										controller.close();
										return;
									}
									// Get the data and send it to the browser via the controller
									controller.enqueue(value);
									push();
								});
							}

							return push();
						},
					});
				})
				.then(stream => {
					// Respond with our stream
					const final = new Response(stream, {
						headers: response.value.headers,
						status: response.value.status,
						statusText: response.value.statusText,
					});
					resolve(final);
				})
				.catch(error => {
					reject(error);
				});
		});
	}
	const useCustomFetch = createFetch({
		baseUrl,
		fetchOptions: {
			mode: 'cors',
		},
		options: {
			fetch: customFetch,
			async beforeFetch({ options }) {
				const cookies = useCookies(['csrftoken']);

				options.headers = {
					...options.headers,
					accept: '*/*',
					'accept-language': 'en-US,en;q=0.9',
					'X-CSRFToken': cookies.get('csrftoken'),

					//allow overriding headers on a per-request basis
					...args?.headers,
				};

				return { options };
			},
			async onFetchError(ctx) {
				if (alertOnFailure) {
					if (supportStore.hostTarget === 'mysfdomain.local') {
						console.error(
							`Your mysfdomain host is set to "mysfdomain.local". Are you running the backend locally? Does this match its hostname?`
						);
					}

					const alertStore = useAlertStore();
					alertStore.addAlert({ message });
				}
				return ctx;
			},
		},
	});

	return reactive({ ...useCustomFetch(url), progress });
}
