import { ref } from 'vue';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';
import { useRoute } from 'vue-router';

export default defineStore('agent/billing', () => {
	const VueRoute = useRoute();

	const receiptList = ref([]);
	const creditsList = ref([]);

	async function getReceiptList() {
		try {
			const { data } = await useApi(`api/agents/${VueRoute.params.agentID}/receipts/`, {
				message: 'There was an issue retrieving your billing info! Please try again later.',
			}).json();

			if (data.value.length > 0) {
				receiptList.value = data.value;
			}
		} catch (error) {
			console.error(error);
			return Promise.resolve();
		}

		return Promise.resolve();
	}

	async function getCreditsList() {
		try {
			const { data } = await useApi(`api/agents/${VueRoute.params.agentID}/billing/`, {
				message:
					"Uh oh, we couldn't get your billing history from the server! Please try again later.",
			}).json();
			creditsList.value = data?.value;

			return Promise.resolve();
		} catch (error) {
			console.error(error);
			return Promise.reject();
		}
	}

	return {
		receiptList,
		creditsList,

		getReceiptList,
		getCreditsList,
	};
});
