import {
	addMonths,
	endOfDay,
	endOfMonth,
	format,
	isBefore,
	parseISO,
	startOfDay,
	startOfMonth,
	subDays,
	subMonths,
} from 'date-fns';
import { productTypes } from '@/constants';

export const notQuIteSO = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export function getMimeType(file, fallback = null) {
	const byteArray = new Uint8Array(file).subarray(0, 4);

	let header = '';
	for (let i = 0; i < byteArray.length; i++) {
		header += byteArray[i].toString(16);
	}

	// you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
	switch (header) {
		case '52494646':
			return 'image/webp';
		case '89504e47':
			return 'image/png';
		case '47494638':
			return 'image/gif';
		case 'ffd8ffe0':
		case 'ffd8ffe1':
		case 'ffd8ffe2':
		case 'ffd8ffe3':
		case 'ffd8ffe8':
			return 'image/jpeg';
		case '25504446':
			return 'application/pdf';
		default:
			return fallback;
	}
}
export function generateDateRangeByCurrent(min_date) {
	const initDate = startOfMonth(parseISO(min_date));
	const lastDate = endOfMonth(subMonths(new Date(), 1));
	const months = [];
	let currentDate = new Date(initDate);

	while (isBefore(currentDate, lastDate)) {
		months.push({
			text: format(currentDate, 'MMMM yyyy'),
			value: {
				start: format(startOfMonth(currentDate), notQuIteSO),
				end: format(endOfMonth(currentDate), notQuIteSO),
			},
		});
		currentDate = addMonths(currentDate, 1);
	}

	months.push(last30Days());
	months.reverse();
	return months;
}
export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shuffle(array) {
	// Durestenfeld: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
	// https://stackoverflow.com/a/12646864
	const _a = [...array];
	for (let i = _a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[_a[i], _a[j]] = [_a[j], _a[i]];
	}
	return _a;
}

export function last30Days() {
	let today = new Date();
	return {
		text: 'Last 30 Days',
		value: {
			start: format(startOfDay(subDays(today, 30)), notQuIteSO),
			end: format(endOfDay(today), notQuIteSO),
		},
	};
}

export function tagStripper(t) {
	return t?.replace(/<[^>]*>/g, ' ') ?? undefined;
}

export const iconSets = {
	empty: ['empty-set', 'inbox', 'cow', 'ram', 'thumbs-up'],
	party: [
		'party-bell',
		'party-horn',
		'face-party',
		'balloons',
		'cake-candles',
		'champagne-glasses',
		'gifts',
		'pinata',
		'badge-check',
		'circle-check',
		'thumbs-up',
		'flag-checkered',
		'handshake-simple',
		'gift',
		'feather',
		'memo-circle-check',
	],
	mort: [
		'skull-cow',
		'skull-crossbones',
		'skull',
		'hand-holding-skull',
		'book-skull',
		'circle-radiation',
		'spider-black-widow',
		'scythe',
		'radiation',
		'fish-bones',
		'face-eyes-xmarks',
		'face-explode',
		'tombstone-blank',
		'volcano',
	],
	spin: [
		'loader',
		'spinner',
		'spinner-third',
		'circle-notch',
		'arrows-spin',
		'asterisk',
		'atom-simple',
		'compact-disc',
		'fan',
		'gear',
		'rotate',
	],
	creature: [
		'ghost',
		'alicorn',
		'narwhal',
		'bat',
		'deer',
		'cow',
		'cat-space',
		'badger-honey',
		'deer-rudolph',
		'dog',
		'elephant',
		'hippo',
		'monkey',
		'otter',
		'rabbit',
		'sheep',
		'squirrel',
		'bird',
		'ram',
		'bee',
		'crab',
		'duck',
		'dragon',
		'turtle',
	],
	disappoint: [
		'face-angry-horns',
		'face-angry',
		'face-anguished',
		'face-diagonal-mouth',
		'face-disappointed',
		'face-disguise',
		'face-dizzy',
		'face-expressionless',
		'face-hand-over-mouth',
		'face-head-bandage',
		'face-icicles',
		'face-raised-eyebrow',
		'face-scream',
		'face-swear',
		'face-sad-cry',
	],
};

export function rndIcon(groups, count = 1) {
	if (!groups) {
		throw new Error(
			`No valid icon groups specified; pick from ${listFormatter.format(
				Object.keys(iconSets)
			)}`
		);
	}
	groups = Array.isArray(groups) ? groups : [groups];
	let icons = [];
	while (count > icons.length) {
		icons.push(...groups.map(g => iconSets[g] || []).flat());
	}
	const picked = shuffle(icons).splice(0, count);
	return count === 1 ? picked[0] : picked;
}
export const moneyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});
export function dateFormatter({ date, showTime = false }) {
	/**
	 * Formats provided date as "MMM dd, yyyy".
	 * Example: dateFormatter({ date: transaction.date, showTime: true })
	 *
	 * @param {number, string, date} date
	 * @param {boolean} showTime whether to append time as " h:mmaaaa"
	 */
	return format(new Date(date), `MMM dd, yyyy${showTime ? ' h:mmaaaa' : ''}`);
}
export const listFormatter = new Intl.ListFormat('en-US');

export function isValidProductType(stringToCheck) {
	return Object.keys(productTypes).includes(stringToCheck);
}

export function findClosestNumberInArray(arrayToCheck, valueToFind) {
	/**
	 * Finds the nearest value in an array of numbers.
	 *
	 * modified from https://stackoverflow.com/a/58593886
	 *
	 * Example: nearestValue(array, 42)
	 *
	 * @param {Array<number>} arr
	 * @param {number} val the ideal value for which the nearest or equal should be found
	 */
	return (
		arrayToCheck.reduce(
			(p, n) => (Math.abs(p) > Math.abs(n - valueToFind) ? n - valueToFind : p),
			Infinity
		) + valueToFind
	);
}
