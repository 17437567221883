<template>
	<section class="relative mb-3 flex flex-col justify-start overflow-auto pl-0">
		<ProgressBar
			v-if="showProgress"
			:value="currentStepperIndex + 1"
			:animate="false"
			:max="stepNames.length"
			:text="`${title ? `${title} - ` : ''}Step ${currentStepperIndex + 1} of ${
				stepNames.length
			}`"
			class="text-bold sticky top-0 z-10 rounded-sm bg-white bg-opacity-90 p-4 text-center text-lg shadow dark:bg-gray-900 dark:!text-gray-200 dark:shadow-gray-600"
			full-width
		/>
		<ol class="list-none">
			<li
				v-for="(step, id, i) in steps"
				:id="`step-${step.name}`"
				:key="id"
				ref="stepRefs"
				class="flex flex-col transition-all"
			>
				<h3>
					<button
						:class="{
							'rounded-md hover:bg-gray-300 dark:hover:bg-gray-700':
								!stepIsDisabled(step),
							[stepIsDisabled(step) ? 'cursor-not-allowed' : 'cursor-pointer']:
								!isCurrent(id),
						}"
						:disabled="stepIsDisabled(step)"
						class="my-2 flex w-fit shrink items-center gap-2 p-1"
						type="button"
						@click="goTo(id)"
						@keydown="goTo(id)"
						:aria-expanded="isCurrent(id)"
						:aria-controls="`step-${id}-content`"
					>
						<span
							:class="[
								stepper.isAfter(id) || isCurrent(id)
									? 'bg-orange-600 text-black'
									: stepIsDisabled(step)
										? 'bg-gray-400 dark:bg-gray-300 dark:text-gray-900'
										: 'dark:bg-gray:200 bg-gray-800 text-gray-200 dark:text-gray-900',
							]"
							class="rounded-full px-3 py-1 font-bold transition-all"
						>
							{{ i + 1 }}
						</span>
						<span
							:class="[
								stepper.isAfter(id) || isCurrent(id)
									? 'font-semibold text-black dark:text-gray-100'
									: stepIsDisabled(step)
										? 'font-light text-gray-400 dark:text-gray-300'
										: 'text-gray-800 dark:text-gray-200',
							]"
						>
							<slot :name="`${step.name}.title`">
								{{ step.title }}
							</slot>
						</span>
					</button>
				</h3>

				<BaseExpand :expanded="isCurrent(id)" :content-id="`step-${id}-content`">
					<div
						:aria-current="isCurrent(id) ? 'step' : false"
						class="ml-5 border-l-2 border-gray-400 pl-3"
					>
						<div
							class="flex flex-col items-stretch gap-2 rounded-xl border-2 border-solid border-gray-300 bg-gray-100 p-4 dark:bg-gray-800 dark:text-white"
						>
							<slot
								:name="`${id}.content`"
								v-bind="{ goToPrevious, goToNext, goTo, reset }"
							/>

							<footer
								v-if="!step.hideFooter"
								class="mt-4 flex items-end justify-end gap-2 rounded-b"
							>
								<slot
									:name="`${id}.footer`"
									v-bind="{ goToPrevious, goToNext, goTo, reset }"
								>
									<BaseButton v-if="!isFirst" color="gray" @click="goToPrevious">
										Back
									</BaseButton>
									<BaseButton
										v-if="!isLast"
										:disabled="!current.isValid()"
										color="primary"
										@click="goToNext"
									>
										<slot :name="`${id}.nextText`"> Next </slot>
									</BaseButton>
								</slot>
							</footer>
						</div>
					</div>
				</BaseExpand>
			</li>
		</ol>
	</section>
</template>

<script setup>
import { computed, onMounted, watchEffect } from 'vue';

import { useStepper } from '@vueuse/core';

import BaseExpand from '@/components/ui/BaseExpand.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const emit = defineEmits(['reset', 'update:current-step-name']);
const props = defineProps({
	stepConfig: {
		type: Array,
		required: true,
		default: () => [],
	},
	title: { type: String, default: null },
	nonLinear: { type: Boolean, default: false },
	initialStep: { type: Number, default: 0 },
	showProgress: { type: Boolean, default: true },
});

const parsedSteps = computed(() => {
	return Object.fromEntries(
		//todo: add the ability to parse the Array even if it's just strings
		props.stepConfig.map(({ name, ...value }, index) => [
			name,
			{
				name,
				index,
				isValid() {
					return true;
				},
				// if supplied, value will override defaults for array index & isValid
				...value,
			},
		])
	);
});

const stepper = useStepper(parsedSteps);
const {
	steps,
	stepNames,
	index: currentStepperIndex,
	current,
	isFirst,
	isLast,
	goTo,
	goToNext,
	goToPrevious,
	isCurrent,
	at,
} = stepper;

onMounted(() => {
	const target = stepNames.value[props.initialStep];
	if (!isCurrent(target)) {
		goTo(target);
	}
});

function reset() {
	goTo(stepNames.value?.[0]);
	emit('reset');
}
function allStepsBeforeAreValid(index) {
	return Array(index)
		.fill(null)
		.every((_, i) => stepper.at(i)?.isValid());
}

function stepIsDisabled({ name, index }) {
	if (props.nonLinear) {
		return !allStepsBeforeAreValid(index);
	}
	return !stepper.isAfter(name);
}

watchEffect(() => {
	emit('update:current-step-name', current.value?.name);
});

defineExpose({
	goTo,
	goToNext,
	goToPrevious,
	at,
	current,
	//todo: add `stepper`?
});
</script>

<style scoped></style>
