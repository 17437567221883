<template>
	<section :aria-label="`${name} Text Editor`" role="application" class="!m-0">
		<header class="sticky top-0 z-10 py-2">
			<h4
				:id="editorLabelId"
				class="flex items-start justify-between text-lg font-bold !leading-tight lg:text-xl dark:text-gray-300"
			>
				{{ name }} Text Editor
			</h4>
			<LoadingSpinner v-if="savingChanges" />
		</header>
		<Field
			v-slot="{ errors, handleChange, value, meta }"
			:name="`Compliance Checker ${name}`"
			:value="props.modelValue"
			:rules="complianceCheck"
			:validate-on-change="false"
			:validate-on-model-update="false"
			class="col-span-3"
		>
			<TextEditor
				v-if="type === 'richtext'"
				:value="value"
				:aria-labelledby="editorLabelId"
				:aria-describedby="disclaimerId"
				class="py-1"
				role="document"
				tabindex="0"
				:height="height"
				:max-character-count="maxCharacterCount"
				:placeholder="placeholder"
				:show-menu="showMenu"
				:warnings="warnings"
				@new-character-count="localCount = $event"
				@update:value="debounceHandleChange(handleChange, $event)"
			/>

			<TextFieldInput
				v-else-if="type === 'text'"
				:model-value="value"
				autofocus
				:aria-labelledby="editorLabelId"
				:aria-describedby="disclaimerId"
				role="document"
				:label="name"
				class="my-2 grow"
				:max-character-count="maxCharacterCount"
				@update:model-value="debounceHandleChange(handleChange, $event)"
			/>

			<PersonalInfoDisclaimer v-if="showDisclaimer" :id="disclaimerId" />

			<div class="flex justify-end my-2 empty:hidden">
				<BaseButton
					v-if="showConfirmButton"
					outline
					dense
					:disabled="!meta.valid"
					class="inline-block"
					@click="$emit('update:modelValue', value)"
				>
					Confirm
				</BaseButton>
			</div>

			<div aria-live="assertive" class="space-y-2 empty:hidden">
				<BaseAlert v-if="isMidCheck" dense outlined type="info">
					<div class="flex items-center gap-2">
						<LoadingSpinner small blue />
						<span>Checking compliance...</span>
					</div>
				</BaseAlert>
			</div>

			<BaseAlert v-if="meta.dirty && meta.valid && !meta.pending" dense type="success">
				This text is compliant.
			</BaseAlert>
			<BaseAlert
				v-if="invalidTerms.length > 0 || generalViolations.length > 0"
				dense
				type="error"
			>
				<div v-if="invalidTerms.length > 0">
					<p class="mb-0">{{ errors[0] }}. Please remove or reword the following:</p>
					<ul
						v-for="(term, index) in invalidTerms"
						:key="`${term.replaceAll(' ', '_')}_${index}`"
						class="list-inside list-disc"
					>
						<li>{{ term }}</li>
					</ul>
				</div>
				<div v-if="generalViolations.length > 0">
					<p class="mb-0">{{ errors[0] }}. Please address the following:</p>
					<ul
						v-for="(msg, index) in generalViolations"
						:key="`${msg.replaceAll(' ', '_')}_${index}`"
						class="list-inside list-disc"
					>
						<li>{{ msg }}</li>
					</ul>
				</div>
			</BaseAlert>
			<BaseAlert v-if="maxCharacterCount && localCount > maxCharacterCount" type="warn">
				<slot name="exceeded-character-count-message"></slot>
			</BaseAlert>

			<BaseAlert v-if="spellingStatus === 'failed'" dense outlined text type="warning">
				There may be misspelled words, please check before saving.
			</BaseAlert>
		</Field>
	</section>
</template>

<script setup>
/*
   IMPORTANT: Do not emit a value that has not been checked for compliance.
   Components that use this checker will assume that only compliant results are emitted.
 */
import { computed, ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import { Field } from 'vee-validate';
import TextEditor from '@mirus/tiptap-editor';

import { tagStripper } from '@/utils';
import useEditorChangesStore from '@/stores/editorChanges';
import { useCompliance } from '@/composables/useCompliance';

import BaseAlert from '@/components/ui/BaseAlert';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import TextFieldInput from '@/components/ui/TextFieldInput';
import BaseButton from '@/components/ui/BaseButton';
import PersonalInfoDisclaimer from '@/components/MXEditor/PersonalInfoDisclaimer';

const editorChangesStore = useEditorChangesStore();
const { savingChanges } = storeToRefs(editorChangesStore);

const emit = defineEmits([
	'update:modelValue',
	'update:compliance-response',
	'update:compliance-text',
]);

const props = defineProps({
	modelValue: { type: String, required: true },

	name: { type: String, required: true, default: 'Text Editor' },
	placeholder: { type: String, default: 'Enter text here' },
	showMenu: { type: Boolean, default: true },
	showDisclaimer: { type: Boolean, default: true },
	maxCharacterCount: { type: Number, default: null },
	height: { type: String, default: '300px' },
	required: { type: Boolean, default: true },
	allowedTerms: { type: Array, default: () => [] },
	confirmChanges: { type: Boolean, default: false },
	domain: { type: String, default: null },
	productType: { type: String, default: null },

	type: {
		type: String,
		default: 'richtext',
		validator: value => {
			return ['richtext', 'text'].includes(value);
		},
	},
});

const compliance = useCompliance();
onUnmounted(() => compliance.clearCompliance());
const isMidCheck = computed(() => compliance.midCheck.value);

const warnings = computed(() => {
	const {
		collapsedViolations = [],
		sanitations = [],
		spellingMistakes = [],
	} = compliance.parsedResult.value ?? {};
	return [...collapsedViolations, ...sanitations, ...spellingMistakes];
});
const spellingStatus = computed(() => {
	if (compliance.parsedResult.value?.spellingMistakes) {
		return compliance.parsedResult.value.spellingMistakes?.length < 1 ? 'passed' : 'failed';
	} else {
		return 'unchecked';
	}
});
const invalidTerms = computed(() => {
	const { collapsedViolations = [], sanitations = [] } = compliance.parsedResult.value ?? {};
	return [...collapsedViolations, ...sanitations].map(({ value }) => value);
});
const generalViolations = computed(() => compliance.parsedResult.value.generalViolations ?? []);

async function complianceCheck(text) {
	compliance.clearCompliance();

	if (text === props.modelValue) {
		return true;
	}

	if (tagStripper(text) === '') {
		return true;
	}

	if (props.allowedTerms.some(v => v === undefined)) {
		return false;
	}

	try {
		await compliance.checkCompliance({
			text: text,
			allowedTerms: props.allowedTerms,
			domain: props.domain,
			productType: props.productType,
		});
		emit('update:compliance-response', compliance.lastRawResponse.value);
		emit('update:compliance-text', text);

		const { isSanitized, isCompliant } = compliance.parsedResult.value;
		if (isCompliant && isSanitized) {
			if (props.confirmChanges) {
				showConfirmButton.value = true;
			} else {
				emit('update:modelValue', text);
			}
			return true;
		}
		return `Some of this text is not compliant`;
	} catch (error) {
		console.error(error);
		return `Our apologies, there was an error while checking compliance. Support has been notified, but you can't make text edits at the moment -- please try again later. Thanks for your patience!`;
	}
}

const debounceHandleChange = useDebounceFn((handleChange, $event) => {
	showConfirmButton.value = false;
	handleChange($event);
}, 800);

const showConfirmButton = ref(false);
const disclaimerId = computed(() => `${props.name.replaceAll(' ', '')}InfoDisclaimer`);
const editorLabelId = computed(() => `${props.name.replaceAll(' ', '')}TextEditorLabel`);

const localCount = ref(null);
</script>

<style lang="scss">
.ProseMirror {
	height: 100%;
	overflow: auto;
}

.tiptap-editor {
	ul {
		list-style: disc;
	}
}
</style>
