<template>
	<section class="h-full overflow-scroll rounded-lg p-2 bg-white dark:bg-gray-800">
		<header
			class="sticky top-0 z-10 flex items-start justify-between bg-white p-2 shadow dark:bg-gray-800"
		>
			<h2 class="shrink-0 text-2xl font-bold">Site Config</h2>
			<LoadingSpinner v-if="loading" text="Loading config..." />
			<BaseToggle
				v-model="domain_transactions_enabled"
				label="Enable Domain Transactions"
				class="shrink-0 rounded border-4 border-solid p-2"
				:class="domain_transactions_enabled ? 'border-green-500' : 'border-red-500'"
			>
				<span class="flex items-center gap-2"> Enable Domain Transactions </span>
			</BaseToggle>
		</header>
		<div class="max-h-full overflow-auto p-2">
			<div class="flex justify-stretch gap-8">
				<DemoUsers v-model:users="demo_users" />
				<DemoDomains v-model:domains="demo_domains" />
			</div>
		</div>
		<footer
			class="sticky bottom-0 mt-8 flex w-full items-start justify-end gap-2 bg-gray-100 p-2 dark:bg-gray-700"
		>
			<BaseButton
				ref="cancelButton"
				:disabled="!meta.dirty || isSubmitting || loading"
				color="gray"
				@click="reset"
			>
				Undo Changes
			</BaseButton>
			<BaseButton
				ref="saveButton"
				:disabled="!meta.dirty || isSubmitting || loading"
				color="primary"
				@click="saveConfig"
			>
				Update
			</BaseButton>
		</footer>
	</section>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useForm } from 'vee-validate';
import { array as yArray, object as yObject, string as yString, boolean as yBoolean } from 'yup';

import useConfigStore from '@/stores/site/config';

import LoadingSpinner from '@/components/ui/LoadingSpinner';
import BaseButton from '@/components/ui/BaseButton';
import BaseToggle from '@/components/ui/BaseToggle';

import DemoUsers from '@/components/MirusControls/DemoUsers';
import DemoDomains from '@/components/MirusControls/DemoDomains';
import { storeToRefs } from 'pinia';

const configStore = useConfigStore();
const { config } = storeToRefs(configStore);
const loading = ref(false);

const schema = computed(() =>
	yObject({
		domain_transactions_enabled: yBoolean().required().default(false),
		demo_domains: yArray().of(yString().required()).default([]),
		demo_users: yArray()
			.of(
				yObject({
					title: yString().required(),
					subtext: yString().nullable(),
					associate_id: yString().required(),
				}).required()
			)
			.default([]),
	})
);
const { resetForm, meta, isSubmitting, defineField } = useForm({
	validationSchema: schema.value,
});
const [domain_transactions_enabled] = defineField('domain_transactions_enabled');
const [demo_domains] = defineField('demo_domains');
const [demo_users] = defineField('demo_users');

function reset() {
	// reset values based on the updated config from the store
	// this also sets the form to pristine
	resetForm({
		values: {
			domain_transactions_enabled: config.value?.domain_transactions_enabled ?? false,
			demo_domains: config.value?.demo_domains ?? [],
			demo_users: config.value?.demo_users ?? [],
		},
	});
}
async function saveConfig() {
	loading.value = true;
	const config = {
		domain_transactions_enabled: domain_transactions_enabled.value,
		demo_domains: demo_domains.value,
		demo_users: demo_users.value,
	};

	await configStore.saveSiteConfig({ config });
	loading.value = false;
}
watch(() => config.value, reset, { immediate: true, deep: true });
onMounted(() => configStore.ensureSiteConfig());
</script>

<style scoped></style>
