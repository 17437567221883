<template>
	<BaseSnackbar :display="saveStatus !== 'hide'">
		<div class="mb-5 items-start rounded-lg border-2 border-gray-300 bg-white p-4">
			<div class="flex place-content-center">
				<div class="my-auto shrink-0">
					<FAIcon
						v-if="saveStatus === 'saving'"
						spin
						class="h-8 w-8 text-blue-400"
						icon="circle-notch"
					/>
					<FAIcon
						v-if="saveStatus === 'saved'"
						class="h-10 w-10 rounded-full border-4 border-blue-400 bg-blue-50 p-2 text-3xl text-blue-600 shadow-md"
						:icon="rndIcon('party')"
					/>
				</div>

				<div class="ml-3 pt-0.5">
					<div v-if="saveStatus === 'saving'">
						<p class="mb-0 text-xl font-medium text-gray-900">
							Updating {{ saveDomain }}...
						</p>
						<div class="mt-1 text-base text-gray-500">
							{{ saveMessage }}
						</div>
					</div>

					<div v-if="saveStatus === 'saved'">
						<p class="text-xl font-medium text-gray-900">
							Nice Work -- Your domain is updated!
						</p>
						<p class="text-lg text-gray-700">
							<span class="font-bold">Get some Tea</span>, or take a quick nap... It
							will be about 10-20 minutes before all the changes to your domain are
							completed and visible to the world. Thank you!
						</p>
					</div>

					<div v-if="saveStatus === 'error'">
						<p class="text-xl font-medium text-red-900">
							Those changes were not saved!
						</p>
						<div
							class="mx-2 my-3 flex justify-start space-x-3 rounded-xl border-2 border-x-red-300 border-y-transparent p-2"
						>
							<div class="mt-1">
								<FAIcon
									icon="skull"
									class="h-4 w-4 rounded-full border-2 border-red-300 bg-red-100 p-1 text-xl text-red-600 shadow-md"
								/>
							</div>

							<div class="flex flex-col items-start space-y-1 text-red-800">
								<div class="font-mono text-base font-bold">Error Details:</div>
								<div class="font-mono text-sm">{{ saveError.message }}</div>
							</div>
						</div>
						<div class="mt-1 text-base text-gray-600">
							Try waiting a few minutes and saving your changes again. If this error
							persists, please make a note of the
							<span class="text-red-600">Error Details</span> and contact us for
							support.
						</div>
					</div>
				</div>
			</div>
		</div>
	</BaseSnackbar>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { rndIcon } from '@/utils';
import BaseSnackbar from '@/components/ui/BaseSnackbar.vue';
import useDomainStore from '@/stores/agent/agent-domains';

const domainStore = useDomainStore();
const { saveStatus, saveError, saveDomain, saveMessage } = storeToRefs(domainStore);
</script>

<style></style>
