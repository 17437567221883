<template>
	<div class="flex w-full flex-col justify-start gap-2 pt-4">
		<div ref="autocompleteEl" class="relative text-gray-900 dark:text-white">
			<label for="search" class="pointer-events-none">
				{{ label }}
			</label>
			<div
				class="h-min w-full border-2 border-solid border-gray-900 py-2 pl-9 pr-12 transition-all dark:border-white"
				:class="[
					showResultsContainer ? 'rounded-t-lg' : 'rounded-lg',
					{
						'focus-within:border-orange-500 dark:focus-within:border-orange-200':
							currentlyFocusedEl === searchInputEl,
					},
				]"
			>
				<input
					id="search"
					ref="searchInputEl"
					v-model="searchInputText"
					autocomplete="off"
					:placeholder="placeholder"
					type="text"
					class="peer flex w-full cursor-text border-0 !p-0 outline-none placeholder:transition-opacity focus:ring-0 focus:placeholder:opacity-100 dark:placeholder-gray-200"
					:class="{
						'placeholder:opacity-0': !selections.length,
					}"
					role="combobox"
					aria-controls="results"
					:aria-expanded="showResultsContainer"
					aria-autocomplete="list"
					v-bind="{
						'aria-label': ariaLabel,
						'aria-describedby': ariaDescribedby,
					}"
					@keydown.down.prevent="focusResult(0)"
					@keydown.enter.prevent="selectFirstResult"
				/>
				<!-- sorry this is after the input but it has to be for 'peer-focus:' to work -->
				<FAIcon
					icon="fa-magnifying-glass"
					class="absolute left-3 h-5 w-5 top-9 transition-colors peer-focus:text-orange-500 dark:peer-focus:text-orange-200"
				/>

				<div class="absolute right-6 top-8 flex h-7 w-7 items-center justify-center gap-2">
					<button
						v-if="showResultsToggle"
						class="items-center justify-center rounded-full outline-none ring-offset-2 transition focus:bg-gray-200 focus:ring-2 focus:ring-gray-800 dark:focus:bg-gray-600 dark:focus:ring-white dark:focus:ring-offset-gray-500"
						@click.prevent.stop="toggleResultsContainer"
					>
						<FAIcon
							:class="{ 'rotate-180': showResultsContainer }"
							class="duration-200"
							fixed-width
							icon="angle-down"
							size="lg"
						/>
					</button>
					<button
						v-if="(searchInputText && clearable) || (selections.length && multiple)"
						class="items-center justify-center rounded-full outline-none ring-offset-2 transition focus:bg-gray-200 focus:ring-2 focus:ring-gray-800 dark:focus:bg-gray-600 dark:focus:ring-white dark:focus:ring-offset-gray-500"
						@click="clearAutocomplete"
					>
						<FAIcon icon="fa-xmark" size="lg" />
					</button>
				</div>
				<div
					v-if="showResultsContainer"
					id="results"
					ref="resultsEl"
					class="group absolute left-0 top-full z-50 flex max-h-96 w-full flex-col gap-1 overflow-clip overflow-y-scroll !rounded-b-lg border-2 border-t-0 border-gray-300 bg-gray-200 p-2 drop-shadow-xl dark:bg-gray-500"
					role="listbox"
				>
					<LoadingSpinner v-if="loading" class="py-2" />
					<button
						v-for="(result, i) in results"
						v-else-if="results.length > 0"
						:key="i"
						class="rounded-lg border-2 border-solid border-transparent bg-white p-2 !no-underline outline-none transition-colors first:bg-orange-50 first:text-orange-700 hover:bg-orange-100 hover:text-orange-700 focus:border-gray-600 focus:bg-orange-100 focus:text-orange-700 dark:bg-gray-600 dark:first:text-orange-300 dark:hover:bg-gray-100 dark:hover:text-gray-900 dark:focus:bg-gray-100 dark:focus:text-gray-900 group-focus-within:[&:not(:focus)]:text-current"
						role="option"
						:aria-selected="`${selections.includes(result)}`"
						@keydown.up.prevent="focusResult(i - 1)"
						@keydown.down.prevent="focusResult(i + 1)"
						@click.prevent="resultClicked(result)"
					>
						<slot name="item" v-bind="result">
							<span class="block text-left text-sm font-semibold">{{ result }}</span>
						</slot>
					</button>
					<slot v-else name="no-data">
						<span class="rounded-lg bg-white py-2 pl-3 font-bold"> No results </span>
					</slot>
				</div>
			</div>
		</div>
		<div v-if="multiple" class="flex w-full flex-wrap content-start gap-2">
			<template v-for="selection in selections">
				<slot name="selection" v-bind="{ selection }">
					{{ selection }}
				</slot>
			</template>
		</div>
	</div>
</template>
<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useActiveElement, onClickOutside } from '@vueuse/core';

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

const emit = defineEmits(['update:search-input', 'change', 'click:clear', 'update:modelValue']);

const props = defineProps({
	ariaAutocomplete: { type: String, required: false, default: 'list' },
	ariaControls: { type: String, required: false, default: 'search' },
	ariaDescribedby: { type: String, required: false, default: 'search' },
	ariaExpanded: { type: Boolean, required: false, default: false },
	ariaLabel: { type: String, required: false, default: 'Search' },
	autofocus: { type: Boolean, required: false, default: false },
	clearable: { type: Boolean, required: false, default: false },
	closeOnContentClick: { type: Boolean, required: false, default: false },
	hideSelected: { type: Boolean, required: false, default: false },
	showResultsToggle: { type: Boolean, required: false, default: false },
	items: { type: Array, required: true },
	label: { type: String, required: true },
	loading: { type: Boolean, required: false, default: false },
	multiple: { type: Boolean, required: false, default: false },
	noFilter: { type: Boolean, required: false, default: false },
	placeholder: { type: String, required: false, default: 'Search' },
	modelValue: { type: [Object, Array], required: false, default: null },
});

const searchInputText = ref('');
const selections = ref([]);

const autocompleteEl = ref();
const searchInputEl = ref();
const resultsEl = ref();
const currentlyFocusedEl = useActiveElement();

onClickOutside(autocompleteEl, () => (showResultsContainer.value = false));

const results = computed(() => {
	if ((props.noFilter || !searchInputText.value) && props.hideSelected) {
		return props.items.filter(item => !selections.value.includes(item));
	}

	if (props.noFilter && !props.hideSelected) {
		return props.items;
	}

	if (!props.noFilter && props.hideSelected && searchInputText.value) {
		return props.items.filter(
			item =>
				!selections.value.includes(item) &&
				item.toLowerCase().includes(searchInputText.value.toLowerCase())
		);
	}

	return props.items.filter(item =>
		item.toLowerCase().includes(searchInputText.value.toLowerCase())
	);
});

const focusIsWithinAutocomplete = computed(() => {
	return autocompleteEl.value?.contains(currentlyFocusedEl.value);
});

const showResultsContainer = ref(false);

async function toggleResultsContainer() {
	if (showResultsContainer.value) {
		searchInputEl.value.focus();
	}

	showResultsContainer.value = !showResultsContainer.value;
}
async function focusResult(i) {
	if (i < 0 || !(results.value.length > 0)) {
		searchInputEl.value.focus();
		return;
	}

	if (results.value.length > 0 && searchInputText.value?.trim() === '') {
		showResultsContainer.value = true;
	}

	if (showResultsContainer.value && i < results.value.length) {
		await nextTick();
		resultsEl.value.querySelectorAll('button')[i].focus();
	}
}

async function resultClicked(result) {
	if (typeof result === 'string' && [null, undefined, ''].includes(result?.trim())) {
		return;
	}
	emit('change', result);
	if (props.multiple) {
		searchInputText.value = '';
		selections.value = [...selections.value, result];
	}
	if (props.closeOnContentClick) {
		setTimeout(() => {
			currentlyFocusedEl.value.blur();
			showResultsContainer.value = false;
			searchInputEl.value.focus();
		}, 1);
	}
	searchInputEl.value.focus();
}

function selectFirstResult() {
	if (results.value.length > 0) {
		if (showResultsContainer.value) {
			resultClicked(results.value[0]);
			showResultsContainer.value = false;
		} else {
			showResultsContainer.value = true;
		}
	}
}

function clearAutocomplete() {
	searchInputText.value = '';
	selections.value = [];
	searchInputEl.value.focus();
	showResultsContainer.value = props.autofocus;
	emit('click:clear');
}

watch(
	() => props.modelValue,
	() => {
		if (props.multiple && props.modelValue !== selections.value) {
			selections.value = props.modelValue;
			searchInputEl.value?.focus();
		}
	},
	{ immediate: true }
);

watch(focusIsWithinAutocomplete, newVal => {
	if (!newVal) searchInputText.value = '';
});

watch(searchInputText, () => {
	showResultsContainer.value = true;
	emit('update:search-input', searchInputText.value);
});

watch(
	selections,
	() => {
		emit('update:modelValue', selections.value);
	},
	{ deep: true }
);

onMounted(() => {
	if (props.autofocus) {
		searchInputEl.value.focus();
	}
});

defineExpose({
	searchInputText,
	results,
	selections,
	resultClicked,
});
</script>
