export default function prepareRoutes(array) {
	const finalArray = [];
	array.forEach(route => {
		const newRoute = { ...route };
		const { children } = route;
		newRoute.pathToRegexpOptions = { strict: true };

		if (children) {
			newRoute.children = prepareRoutes(children);
		}
		finalArray.push(newRoute);
		const hasTrailingSlash = newRoute.path.match(/\/\*?$/);
		if (
			!(hasTrailingSlash || newRoute.path === '') &&
			newRoute.path !== '*' &&
			process.env.NODE_ENV === 'development'
		) {
			alert(
				`The route "${newRoute.path}" should have a trailing slash! Please check the console for details and update the route in baseRoutes`
			);
			console.warn(newRoute);
		} else {
			finalArray.push({ path: newRoute.path.replace(/\/*?$/, ''), redirect: newRoute.path });
		}
		return newRoute;
	});
	return finalArray;
}
