import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useFetch } from '@vueuse/core';

const POLLING_FREQUENCY = 1000 * 60 * 5;

export default defineStore('appVersion', () => {
	const versionNumber = ref(null);
	const intervalID = ref(null);
	const foundNewVersion = ref(false);

	async function checkForNewVersion() {
		const { error, data } = await useFetch('/version.txt').get().text();
		if (error.value) {
			return null;
		}
		if (data.value) {
			if (versionNumber.value && versionNumber.value !== data.value) {
				foundNewVersion.value = true;
			}
			versionNumber.value = data.value;
		}
		return data.value;
	}

	async function periodicallyCheckForNewVersion() {
		if (!intervalID.value) {
			await checkForNewVersion();
			intervalID.value = setInterval(checkForNewVersion, POLLING_FREQUENCY);
		}
	}

	function updateApp() {
		foundNewVersion.value = false;
		location.reload();
	}

	return {
		versionNumber,
		foundNewVersion,

		periodicallyCheckForNewVersion,
		updateApp,
	};
});
