import { defineStore } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { ref } from 'vue';

const { width } = useWindowSize();

export default defineStore('navigation', () => {
	const showHeader = ref(false);
	const showNavigation = ref(false);
	const headerLabel = ref('');
	const drawerIsOpen = ref(width.value > 640);
	const navSkipLink = ref(null);
	const skipLink = ref(null);

	function hideDrawerOnSmallScreens() {
		if (width.value < 640) {
			this.drawerIsOpen = false;
		}
	}

	return {
		showHeader,
		showNavigation,
		headerLabel,
		drawerIsOpen,
		navSkipLink,
		skipLink,

		hideDrawerOnSmallScreens,
	};
});
