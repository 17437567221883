import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('alerts', () => {
	const alerts = ref([]);

	function addAlert({ error, message, type = 'error', timestamp = new Date() }) {
		const newAlert = {
			timestamp,
			error,
			message,
			type,
		};
		alerts.value = [...(alerts.value || []), newAlert];
		if (error) {
			// TODO: handle this better/figure out how it relates to Sentry
			console.error(error);
		}
	}
	function dismissAlert(timestamp) {
		alerts.value = alerts.value.filter(alert => alert.timestamp !== timestamp);
	}

	return {
		alerts,

		addAlert,
		dismissAlert,
	};
});
