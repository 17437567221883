<template>
	<section
		:aria-labelledby="`${title.replaceAll(/\s/g, '_')}_table_caption`"
		class="relative flow-root max-h-full w-full overflow-auto @container dark:text-gray-100"
		tabindex="0"
	>
		<header v-if="showTitle" class="sticky left-0 top-0" :class="showSearch ? 'mb-2' : 'mb-4'">
			<component
				:is="headingLevel"
				:class="{
					'text-2xl font-semibold leading-none text-gray-700 dark:text-gray-100':
						headingLevel === 'h2',
					'font-semibold uppercase leading-none tracking-wider text-gray-500 dark:text-gray-200':
						headingLevel === 'h3',
				}"
			>
				{{ title }}
			</component>
			<p
				v-if="description"
				:id="`table-${uid}-description`"
				class="max-w-screen-sm text-sm text-gray-800 dark:text-gray-200"
			>
				{{ description }}
			</p>
		</header>

		<TextFieldInput
			v-if="showSearch"
			v-model="search"
			:label="`Search ${keyword}`"
			:placeholder="`Search ${keyword}`"
			class="sticky left-0"
		/>

		<div v-if="$slots.filter" class="sticky left-0 mb-4">
			<slot name="filter" />
		</div>

		<table
			class="relative z-0 inline-table max-h-full w-full overflow-clip rounded-t-md"
			:aria-describedby="`table-${uid}-description`"
		>
			<caption :id="`${title.replaceAll(/\s/g, '_')}_table_caption`" class="sr-only">
				{{
					title
				}}
			</caption>
			<thead class="sticky top-0 z-10 w-auto max-w-full">
				<tr>
					<th
						v-for="header in headers"
						:key="header.value"
						:class="[
							dense
								? 'p-1 @5xl:px-1.5 @5xl:py-2'
								: 'px-1 py-0.5 @3xl:px-1.5 @3xl:py-2 @5xl:px-3 @5xl:py-4',
							{
								'text-center': header.centered,
							},
						]"
						class="bg-gray-200 text-left text-sm font-semibold text-gray-900 dark:bg-gray-800/50 dark:text-gray-100"
						scope="col"
					>
						<div class="inline-flex shrink-0 items-center gap-0.5 whitespace-nowrap">
							<slot :name="`header.${header.value}`" v-bind="{ ...header }">
								{{ header.text }}
							</slot>

							<button
								v-if="header.sortable"
								:class="[
									{
										'rotate-180':
											sortBy === (header.sortBy || header.value) &&
											sortDescending,
									},
									sortBy === (header.sortBy || header.value)
										? 'text-orange-600'
										: 'text-gray-500 hover:text-gray-800 focus-visible:text-gray-800 dark:hover:text-gray-100 dark:focus-visible:text-gray-100',
								]"
								class="h-5 w-5 rounded-full outline-none transition focus-visible:ring-2 focus-visible:ring-current focus-visible:ring-offset-0"
								:aria-label="`Sort by ${header.text} ${sortDescending ? 'ascending' : 'descending'}`"
								@click="updateSort(header.sortBy || header.value)"
							>
								<FAIcon icon="arrow-up" aria-hidden="true" />
							</button>
						</div>
					</th>

					<th
						v-if="hasExpandedSlot && hasExpandableItem"
						class="bg-gray-200 text-left text-sm font-semibold text-gray-900 dark:bg-gray-800/50 dark:text-gray-100"
					>
						<span class="sr-only">Expand icon</span>
					</th>
				</tr>
			</thead>

			<tbody
				v-if="filteredSortedItems.length === 0"
				class="divide-y divide-gray-200 bg-white dark:bg-gray-800"
			>
				<tr>
					<td
						:colspan="maxColSpan"
						class="whitespace-nowrap bg-gray-50 py-3 text-center font-medium text-gray-600 dark:bg-gray-900 dark:text-gray-100"
					>
						No {{ keyword }} found
					</td>
				</tr>
			</tbody>

			<tbody v-else class="container sticky bottom-0">
				<template v-for="(item, i) in paginatedItems" :key="`${item[itemUniqueKey]}-row`">
					<!-- Core data elements of table -->
					<tr
						:class="[
							{
								'cursor-pointer hover:bg-orange-100 dark:hover:bg-orange-600/25':
									itemIsExpandable(item),
							},
							i % 2 ? 'bg-gray-100 dark:bg-gray-800' : 'bg-white dark:bg-gray-700/75',
						]"
						class="outline-none ring-inset ring-orange-500 focus-visible:ring"
						:tabindex="itemIsExpandable(item) ? 0 : -1"
						:aria-expanded="
							hasExpandedSlot && hasExpandableItem
								? itemIsExpandable(item) &&
									expandedItems.includes(item[itemUniqueKey])
								: undefined
						"
						:aria-controls="
							hasExpandedSlot && hasExpandableItem
								? `item-${item[itemUniqueKey]}-details`
								: undefined
						"
						@click="() => expand(item)"
						@keydown.enter="() => expand(item)"
					>
						<td
							v-for="header in headers"
							:key="header.value"
							:class="[
								dense
									? '@5xl:px-1.5 @5xl:py-2'
									: '@3xl:px-1.5 @3xl:py-2 @5xl:px-3 @5xl:py-4',
								header.wrap ? 'break-words' : 'whitespace-nowrap',
								{
									'max-w-[10vw] truncate': header.truncate,
									'text-center': header.centered,
								},
							]"
							class="truncate p-1 text-sm"
							:title="item[header.value]"
						>
							<slot :name="`item.${header.value}`" v-bind="{ ...item }">
								{{ item[header.value] }}
							</slot>
						</td>

						<template v-if="hasExpandedSlot && hasExpandableItem">
							<td v-if="itemIsExpandable(item)" aria-hidden="true">
								<FAIcon
									:icon="
										expandedItems.includes(item[itemUniqueKey])
											? 'minus'
											: 'plus'
									"
									class="pr-4"
								/>
							</td>
							<td v-else></td>
						</template>
					</tr>
					<!-- Expandable details -->
					<tr
						v-show="
							itemIsExpandable(item) && expandedItems.includes(item[itemUniqueKey])
						"
						:id="`item-${item[itemUniqueKey]}-details`"
						class="bg-gray-200 dark:bg-gray-900"
					>
						<td :colspan="maxColSpan">
							<slot name="item_expanded" v-bind="{ ...item }" />
						</td>
					</tr>
				</template>
			</tbody>
		</table>
		<div
			class="sticky left-0 flex flex-col items-end gap-x-4 gap-y-2 rounded-b-md bg-gray-200 p-2 @3xl:flex-row @3xl:items-center dark:bg-gray-800/50"
		>
			<TablePaginator
				v-if="showPagination && filteredSortedItems.length > 0"
				v-model:end-index="endIndex"
				v-model:rows-per-page="rowsPerPage"
				v-model:start-index="startIndex"
				:item-count="filteredSortedItems.length"
				:title="title"
				:range="paginatorRange"
			/>
			<slot name="toolbar" />
		</div>
	</section>
</template>

<script setup>
import { computed, ref, watchEffect, useSlots } from 'vue';
import TextFieldInput from '@/components/ui/TextFieldInput';
import TablePaginator from '@/components/ui/TablePaginator.vue';

const props = defineProps({
	items: { type: Array, required: true },
	headers: { type: Array, required: true },
	itemUniqueKey: { type: String, required: true },

	headingLevel: { type: String, default: 'h2' },
	title: { type: String, default: 'Sortable Table' },
	description: { type: String, default: null },
	keyword: { type: String, default: 'items' },

	showTitle: { type: Boolean, default: false },
	showSearch: { type: Boolean, default: false },
	showPagination: { type: Boolean, default: false },
	expandAll: { type: Boolean, default: true },

	dense: { type: Boolean, default: false },

	initialSortBy: { type: String, default: null },
	sortDesc: { type: Boolean, default: false },
	paginatorRange: { type: Number, default: 2 },
});
const slots = useSlots();

const uid = crypto.randomUUID();
const sortBy = ref(props.initialSortBy);
const sortDescending = ref(props.sortDesc);
const search = ref('');
const rowsPerPage = ref(5);
const startIndex = ref(0);
const endIndex = ref(4);
const expandedItems = ref([]);

const hasExpandedSlot = computed(() => Boolean(slots.item_expanded));
const hasExpandableItem = computed(
	() => props.expandAll || props.items.some(item => item.expandable)
);

const maxColSpan = computed(() => {
	// if expanded we add another col for the plus icon
	if (hasExpandedSlot.value && hasExpandableItem.value) {
		return props.headers.length + 1;
	}
	return props.headers.length;
});

const filterableItems = computed(() =>
	props.headers.filter(({ filterable }) => Boolean(filterable))
);

const filteredSortedItems = computed(() => {
	let finalItemList = [...props.items];
	if (search.value?.trim() !== '') {
		// get list of searchable fields for each item
		finalItemList = finalItemList.filter(item =>
			filterableItems.value.some(({ value }) =>
				item[value]?.toLowerCase().includes(search.value?.toLowerCase().trim())
			)
		);
	}
	if (sortBy.value) {
		finalItemList.sort((a, b) => {
			if (a[sortBy.value] < b[sortBy.value]) {
				return -1;
			} else if (a[sortBy.value] > b[sortBy.value]) {
				return 1;
			}
			return 0;
		});
		if (sortDescending.value) {
			finalItemList.reverse();
		}
	}
	return finalItemList;
});

const paginatedItems = computed(() => {
	return props.showPagination
		? [...filteredSortedItems.value].slice(startIndex.value, endIndex.value)
		: filteredSortedItems.value;
});

function updateSort(path) {
	if (path !== sortBy.value) {
		sortBy.value = path;
		sortDescending.value = false;
	} else {
		sortDescending.value = !sortDescending.value;
	}
}

function expand(item) {
	if (!itemIsExpandable(item)) {
		return;
	} else if (expandedItems.value.includes(item[props.itemUniqueKey])) {
		// collapse if already expanded
		const i = expandedItems.value.indexOf(item[props.itemUniqueKey]);
		expandedItems.value.splice(i, 1);
	} else {
		expandedItems.value.push(item[props.itemUniqueKey]);
	}
}

function itemIsExpandable(item) {
	return hasExpandedSlot.value && (item.expandable || props.expandAll);
}

watchEffect(() => {
	if (!props.items.every(item => Object.hasOwn(item, props.itemUniqueKey))) {
		throw new Error(
			`SortableTable was provided an itemUniqueKey of ${props.itemUniqueKey}, but not every item provided has that property.`
		);
	}
});

defineExpose({
	rowsPerPage,
});
</script>

<style scoped>
.container {
	position: relative;
	padding: 0;
}
</style>
