<template>
	<fieldset :disabled="disabled" class="relative w-full">
		<div class="flex justify-between">
			<label
				:for="id"
				class="mb-0.5 text-xs"
				:class="
					disabled
						? 'text-gray-500 dark:text-gray-300'
						: 'text-gray-600 dark:text-gray-200'
				"
			>
				{{ label }}
			</label>
			<span
				class="text-xs font-light"
				:class="
					disabled
						? 'text-gray-500 dark:text-gray-400'
						: 'text-gray-600 dark:text-gray-300'
				"
			>
				<slot name="hint"></slot>
			</span>
		</div>
		<input
			ref="input"
			:value="props.modelValue"
			v-bind="{ ...$attrs, id, type, step }"
			class="peer h-9 w-full rounded border border-solid border-gray-500 bg-white p-2 pr-1 text-black focus:border-gray-500 focus:ring-2 focus:ring-orange disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-500 dark:bg-gray-400 dark:text-gray-200 dark:ring-gray-600 dark:focus:ring-orange-400 dark:enabled:bg-transparent sm:text-sm"
			:maxlength="maxCharacterCount"
			:placeholder="placeholder"
			@blur="$emit('blur')"
			@input="$emit('update:modelValue', $event.target.value)"
		/>

		<p v-if="maxCharacterCount" class="mt-2 w-full gap-2 text-right text-xs">
			{{ maxCharacterCount - modelValue.length }} character(s) remaining
		</p>
		<p
			v-if="$slots.message || errorMessage"
			class="font-med !m-0 mb-0 flex items-center text-xs text-red-600"
		>
			<slot name="message">
				{{ errorMessage }}
			</slot>
		</p>
		<div v-else-if="marginBottom" class="mb-4"></div>
	</fieldset>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const input = ref('input');
defineEmits(['update:modelValue', 'blur']);
const props = defineProps({
	modelValue: { type: [String, Number], default: '' },
	errorMessage: { type: String, default: null },
	label: { type: String, required: true },
	type: { type: String, default: 'text' },
	step: { type: [Number, String], default: null },
	customID: { type: String, default: null },
	placeholder: { type: String, default: null },
	disabled: { type: Boolean, default: false },
	autofocus: { type: Boolean, default: false },
	maxCharacterCount: { type: Number, default: null },
	messageAbsolute: { type: Boolean, default: false },
	marginBottom: { type: Boolean, default: true },
});

const id = computed(() => `${(props.customID || props.label).replace(/\s/g, '-')}-input`);

function focusInput() {
	input.value.focus();
}
onMounted(() => {
	if (props.autofocus) {
		focusInput();
	}
});
defineExpose({ focus: focusInput });
</script>
