<template>
	<section
		:class="[
			{
				dense,
				outline,
			},
			colorClass,
			roundedClass,
		]"
		class="baseAlert"
	>
		<div class="w-full flex px-2" :class="outerJustify">
			<div class="flex justify-between gap-4 items-center">
				<FAIcon v-if="icon" :icon="iconName" />
				<span class="whitespace-normal">
					<slot />
				</span>
			</div>
			<slot name="action" />
		</div>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import { getRoundedClass, getColorClass } from '@/utils/styles';

const props = defineProps({
	type: { type: String, default: 'info' },
	outerJustify: { type: String, default: 'justify-between' },
	icon: { type: Boolean, default: true },
	dark: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	outline: { type: Boolean, default: false },
	rounded: { type: [String, Boolean], default: true },
});

const colorClass = computed(() => getColorClass(props.type));
const roundedClass = computed(() => getRoundedClass(props.rounded));

const iconName = computed(() => {
	switch (props.type) {
		case 'success':
			return 'circle-check';
		case 'error':
			return 'triangle-exclamation';

		case 'warn':
			return 'circle-exclamation';

		case 'mirus':
			return 'circle-info';

		case 'info':
		default:
			return 'circle-info';
	}
});
</script>

<style scoped lang="scss">
.baseAlert {
	@apply flex items-center gap-2 p-4;
	@apply bg-white dark:bg-gray-800 dark:text-white;

	&.outline {
		@apply border-2 border-solid border-current;
	}
	&.successGreen {
		@apply bg-green-200 text-green-900 dark:border-green-700 dark:bg-green-700 dark:text-green-100;
	}

	&.errorRed {
		@apply bg-red-100 text-red-700 dark:border-red-700 dark:bg-red-700 dark:text-red-100;

		&.outline {
			@apply border-red-700;
		}
	}

	&.warnYellow {
		@apply bg-yellow-50 text-yellow-700 dark:border-yellow-700 dark:bg-yellow-700 dark:text-yellow-50;

		&.outline {
			@apply border-yellow-700;
		}
	}

	&.mirusOrange {
		@apply bg-orange-100 text-orange-600 dark:border-orange-500 dark:bg-red-500 dark:text-red-100;
	}

	&.infoBlue {
		@apply bg-blue-100 text-blue-700 dark:border-blue-700 dark:bg-blue-700 dark:text-blue-100;
	}

	&.outline {
		&,
		&.errorRed,
		&.warnYellow,
		&.successGreen,
		&.mirusOrange,
		&.infoBlue,
		&.mirusGray {
			@apply bg-transparent outline-none dark:bg-transparent;
		}
	}

	&.dense {
		@apply p-1.5;
	}
}
</style>
