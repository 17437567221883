import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { compareAsc } from 'date-fns';
import { escape } from 'lodash';

import useEditorStore from '@/stores/editor';
import useUserStore from '@/stores/user';
import { useApi } from '@/composables/useApi';
import { usePeachy } from '@/composables/usePeachy';

export function useCompliance() {
	const route = useRoute();
	const peachy = usePeachy();

	const apiResponse = ref(undefined);
	const timestamp = ref(undefined);
	const parsedResult = ref({});
	const abortMethod = ref(null);
	const lastRawResponse = ref({});
	const midCheck = ref(false);

	async function checkCompliance({
		text,
		allowedTerms,
		domain = null,
		productType = null,
		allowNonEnglish = false,
	}) {
		abortMethod.value?.();

		const domainName = domain ? domain : route.params.domainName;
		const agentID = route.params.agentID;
		if (productType === null) {
			productType = useEditorStore().productType;
		}

		const { user } = useUserStore();
		const nowTimestamp = new Date();
		timestamp.value = nowTimestamp;

		midCheck.value = true;

		const sharedFields = {
			original_text: text,
			identity_id: user.user,
			identity_organization: user.user_type,
		};
		if (domainName) {
			try {
				const fetcher = useApi(`api/mxeditor/${domainName}/compliance/`, {
					message: `Sorry!  The compliance vendor is not currently responding.  Please try again later.`,
				});

				//check if this is the latest validation attempt
				if (compareAsc(nowTimestamp, timestamp.value) === -1) {
					// return early and ignore results because there's a more recent check
					return;
				}
				abortMethod.value = fetcher.abort();
				const { data } = await fetcher.post({ text, allowedTerms, allowNonEnglish }).json();
				lastRawResponse.value = data.value ?? {};

				const {
					lexiconViolations = [],
					collapsedViolations = [],
					generalViolations = [],
					categoryViolations = [],
					isCompliant,
				} = data.value?.compliance?.assessment ?? {};

				const spellingMistakes = data.value?.spelling?.map(r => ({
					value: r.origWord,
					message: r.suggestions ? 'Did you mean...' : 'Word may be misspelled',
					options: r.suggestions,
					overrideClass: 'underline-blue',
					type: 'spelling',
				}));
				const sanitations =
					data.value?.sanitizer?.map(s => ({
						message: 'this text is not allowed',
						isWord: false,
						value: escape(s),
					})) ?? [];

				const isSanitized =
					data.value?.sanitizer === null || data.value?.sanitizer?.length === 0;
				const isOnlyCategoryViolations =
					lexiconViolations.length === 0 && categoryViolations.length > 0;
				const newParsedResult = {
					lexiconViolations,
					collapsedViolations,
					generalViolations,
					categoryViolations,
					spellingMistakes,
					sanitations,
					isSanitized,
					isCompliant,
				};

				apiResponse.value = data.value || {};
				parsedResult.value = newParsedResult;

				await peachy('compliancecheck', {
					api_response: data.value?.compliance,
					is_compliant: isCompliant,
					disposition: data.value.compliance?.disposition,
					empty_compliance_error: isOnlyCategoryViolations,
					empty_compliance_categories: isOnlyCategoryViolations ? categoryViolations : [],
					violations: lexiconViolations.map(v => ({
						text: v.value,
						category: v.category,
					})),
					error: null,
					product_type: productType.value,
					domain_name: domainName,
					config_associate_id: agentID,

					...sharedFields,
				});
				midCheck.value = false;
				return Promise.resolve();
			} catch (error) {
				peachy('compliancecheck', {
					api_response: error?.message,
					api_response_status: error?.status,
					is_compliant: false,
					disposition: null,
					empty_compliance_error: false,
					empty_compliance_categories: [],
					violations: [],
					error: error?.message,

					...sharedFields,
				});
				console.error(error);
				midCheck.value = false;
				return Promise.reject(error);
			}
		} else {
			midCheck.value = false;
			return Promise.reject('No domain name to check for overrides');
		}
	}
	function clearCompliance() {
		abortMethod.value?.();
		apiResponse.value = undefined;
		timestamp.value = undefined;
		parsedResult.value = {};
	}
	return {
		checkCompliance,
		parsedResult,
		apiResponse,
		clearCompliance,
		lastRawResponse,
		midCheck,
	};
}
