export default {
	bots_crawlers: {
		title: 'Bots & Crawlers',
		description: `Bots are nonhuman traffic sources that generate page views, visits, and other site activity. Crawlers are specific code/programs that ‘crawl’ websites to determine what information exists and is relevant on a site to help people find what they are looking for when searching online.`,
		notes: [`This data is not included in other stats`],
	},
	contact_forms_sent: {
		title: 'Contact Forms Sent',
		description: `The number of times a site visitor filled out the 'Contact Us' form and the 'Send Email or Send' button was used to send you an email from your page.`,
		notes: [
			`Emails are sent by Mirus directly to the <strong>agent’s</strong> State Farm Outlook email address.`,
		],
	},
	links_clicked_to_external_sites: {
		title: 'Links Clicked to External Sites',
		description: `The site visitor ‘clicks’ a link, button, image, etc. on your page which takes them to the external website shown in the list provided.`,
	},
	map_directions_clicks: {
		title: 'Map & Directions Clicks',
		description: `The number of times a site visitor clicks on the map image or 'Map & Directions' link to get directions to your office.`,
		notes: [
			`If you have a banner map image displaying at the top of your M1 site, it is a static image that is not clickable or counted in the metrics.`,
		],
	},
	mirus_metrics_vs_sf_metrics: {
		title: 'Mirus Metrics vs. State Farm Metrics',
		description: `Any metrics displayed on Mirus dashboards will be different from the metrics provided by State Farm, as  Mirus and State Farm use different tools to pull the data.  Additionally, different tools may have  different ways to pull and define the metrics.`,
	},
	page_views: {
		title: 'Page Views',
		description: `Number of times your page was viewed, by real human visitors (not bots). A page view is counted each time a site visitor lands on or refreshes your page.`,
		examples: [
			`If a site visitor lands on your page, then refreshes the page 4 times, the dashboard will show 4 Page Views.`,
		],
		notes: [`Clicks to “Continue a Saved Quote” are not counted as Quote Starts`],
	},
	page_views_by_device_type: {
		title: 'Page Views by Device Type',
		description: `Depicts a pie chart that shows the number of Page Views by device type for the time frame chosen. Hover over the 'piece of the pie' in the graph to see that device type’s specific number of Page Views.`,
	},
	page_views_by_referrer: {
		title: 'Page Views by Referrer',
		description: `Various sites can be listed here, based on where (what website) the site visitor was viewing, prior to clicking on something on that site, that brought them to your page. You will most often see google.com (since it is the most used search engine).`,
	},
	page_views_over_time: {
		title: 'Page Views Over Time',
		description: `Any metrics displayed on Mirus dashboards will be different from the metrics provided by State Farm, as  Mirus and State Farm use different tools to pull the data.  Additionally, different tools may have  different ways to pull and define the metrics.`,
	},
	phone_clicks: {
		title: 'Phone Clicks',
		description: `
		The number of times a site visitor clicked on your phone number from anywhere that it is displayed on your page. When a site visitor 'clicks' on your phone number, it does not mean they actually called your office. The person may not have not hit the ‘call’ button from their mobile device or they may have clicked your phone number on a desktop device that they could not make a call from.
		`,
	},
	sms_clicks: {
		title: 'SMS Clicks',
		description: `
		The number of times a site visitor clicked on your phone number from anywhere that it is displayed on your page with the "sms:" prefix. When a site visitor 'clicks' on your phone number, it does not mean they actually sent a message to the number. The user might not compose or send a message, or they may have clicked from a device that does not have SMS capabilities.
		`,
	},
	quote_starts: {
		title: 'Quote Starts',
		description: `Number of times the 'Get a Quote' or ‘Go’ button, in the Quote Module, on your page was clicked to start a quote. This does not mean the quote was actually started on statefarm.com or that the quote turned into a lead. Mirus is <b>not currently</b> privy to what happens after a site visitor leaves your page or to any quote start/complete data captured by State Farm.`,
		examples: [
			`If a site visitor types in their zip code in the Quote Module and clicks ‘Get a Quote’, this will count as 1 Quote Start.`,
		],
		notes: [`Clicks to “Continue a Saved Quote” are not counted as Quote Starts`],
	},
	text_clicks: {
		title: 'Text Clicks',
		description: `
		The number of times a site visitor, who is using a mobile device, clicked on the text button displayed at the top of your page. When a site visitor 'clicks' on text button, it does not mean they actually texted your office. The person may not have not opened the text application on their mobile device or they may have clicked the text button using a desktop device that they could not text from.
		`,
		notes: [
			`Agents must be enrolled for SF Connect in order to have the Text button display on M1 and subsequently receive Text Click data.`,
		],
	},
	visits: {
		title: 'Visits',

		description: `The number of unique/distinct real human site visitors who visited your page within a 24 hour period. The number of Visits will almost always be less than the number of Page Views.`,
		examples: [
			`If a site visitor visits your page 4 times in a 24 hour period from the same device, the dashboard will show 1 Visits and 4 Page Views.`,
		],
	},
	your_page: {
		title: 'Your Page',
		description: `Refers to <i>your domain URL</i> (e.g. MyAwesomeAgency.com). If you have more than one domain, all data is added together and shown on your dashboard.`,
	},
	campaign_ids: {
		title: 'Campaign IDs',
		description:
			'Campaign IDs or cmpids are a series of specific numeric/alpha characters added to the end of your M1 domain and/or Quote Landing page URLs that allow you to track different digital advertising campaigns and tactics. If Campaign IDs are not set up and attached to the URL properly, detailed campaign reporting cannot be provided. Use the Link Generator to assist you in generating a URL with a Campaign ID attached.',
		notes: [
			'Mirus captures and provides you detailed Campaigns data for all types of Campaign IDs that have been added to your URL(s). Mirus has no knowledge of which marketing vendor or what digital advertising campaigns and tactics are represented by the Campaign IDs you may have.',
		],
	},
};
