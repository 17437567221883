<template>
	<fieldset :disabled="disabled" class="relative">
		<div class="flex justify-between">
			<label
				:for="id"
				:class="
					disabled
						? 'text-gray-500 dark:text-gray-300'
						: 'text-gray-600 dark:text-gray-200'
				"
				class="text-xs"
			>
				{{ label }}
			</label>
			<p v-if="hint" class="mt-2 text-xs text-gray-500 dark:text-gray-300">
				{{ hint }}
			</p>
		</div>

		<textarea
			v-bind="{ ...$attrs, cols, rows }"
			:id="id"
			ref="input"
			:value="modelValue"
			class="peer w-full rounded border border-solid border-gray-500 pr-1 placeholder-transparent focus:border-gray-500 focus:ring-2 focus:ring-orange disabled:cursor-not-allowed disabled:border-gray-300 dark:ring-gray-600 dark:focus:ring-orange-400 sm:text-sm"
			:class="{ 'resize-none': !resizable }"
			:placeholder="placeholder"
			@blur="$emit('blur')"
			@input="handleChange"
		/>

		<p v-if="message" class="mt-2 text-sm text-red-600">
			{{ message }}
		</p>
		<div v-else class="mb-4"></div>
	</fieldset>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'blur']);
const props = defineProps({
	label: {
		type: String,
		required: true,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
	message: {
		type: String,
		default: null,
	},
	modelValue: {
		type: String,
		default: null,
	},

	placeholder: {
		type: String,
		default: null,
	},
	hint: {
		type: String,
		default: null,
	},
	autofocus: { type: Boolean, default: false },
	resizable: { type: Boolean, default: true },
	disabled: { type: Boolean, default: false },
	cols: { type: Number, default: null },
	rows: { type: Number, default: null },
});

const input = ref(null);
const id = computed(() => `${props.label.replace(/\s/g, '-')}-textarea`);

function handleChange(event) {
	emit('update:modelValue', event.target.value);
}

onMounted(() => {
	if (props.autofocus) {
		input.value.focus();
	}
});
</script>
