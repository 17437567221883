<template>
	<label
		:class="{ dark }"
		class="!flex w-full flex-col items-center text-orange-500 dark:text-white"
	>
		<span v-if="text" :class="{ 'sr-only': !showText }" class="mb-1 text-lg font-medium">
			{{ text }}
		</span>
		<progress
			:value="value"
			:max="max"
			class="progress"
			:class="{ [heightClass]: true, animate, 'w-full': fullWidth }"
		/>
	</label>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	value: { type: Number, default: 0 },
	max: { type: Number, default: 100 },
	height: { type: Number, default: 2 },
	animate: { type: Boolean, default: true },
	text: { type: String, default: null },
	dark: { type: Boolean, default: false },
	fullWidth: { type: Boolean, default: false },
	showText: { type: Boolean, default: true },
});

const heightClass = computed(() => {
	switch (props.height) {
		case 3:
		case 4:
			return 'h-4';
		case 5:
		case 6:
			return 'h-6';
		case 7:
		case 8:
			return 'h-8';
		case 9:
		case 10:
			return 'h-10';

		case 1:
			return 'h-1';
		case 2:
		default:
			return 'h-2';
	}
});
</script>

<style lang="scss">
.progress {
	@apply block rounded-full shadow-inner;

	&,
	&::-webkit-progress-bar {
		@apply appearance-none rounded-full bg-gray-200 dark:bg-gray-700;
	}

	&::-webkit-progress-value {
		@apply rounded-full bg-gradient-to-r from-orange-400 to-orange-600;
	}

	&::-moz-progress-bar,
	&::-webkit-progress-value {
		@apply rounded-full bg-gradient-to-r from-orange-400 to-orange-500;
	}
}
</style>
