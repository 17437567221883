<template>
	<section
		class="flex flex-col overflow-y-auto rounded-md bg-white text-sf-charcoal dark:bg-gray-800 dark:text-gray-100"
	>
		<header
			v-if="$slots.header"
			class="sticky top-0 flex items-start justify-between gap-1 bg-white p-3 text-xl font-bold dark:bg-gray-800 md:gap-2"
		>
			<slot name="header" />
		</header>
		<div class="px-3" :class="{ 'pt-3': !$slots.header, 'pb-3': !$slots.footer }">
			<slot name="default" />
		</div>
		<footer
			v-if="$slots.footer"
			class="sticky bottom-0 flex items-end justify-end gap-1 bg-white p-3 dark:bg-gray-800 md:gap-2"
		>
			<slot name="footer" />
		</footer>
	</section>
</template>
<script setup></script>

<style scoped></style>
