<template>
	<div
		class="flex h-[38rem] max-w-[100%] items-stretch justify-stretch overflow-x-auto drop-shadow-xl"
	>
		<ul class="sticky left-0 z-10 min-w-fit grow-0 list-none pl-0 bg-gray-700" role="tablist">
			<li class="m-2">
				<div
					class="rounded-md bg-gray-900 text-gray-100 py-1 px-2 whitespace-nowrap mb-2 text-xs"
				>
					UI Version:&nbsp;
					<span v-if="LOCALDEV" class="font-mono">LOCAL DEVELOPMENT</span>
					<AppLink
						v-if="versionNumber"
						:href="`https://gitlab.mirus.io/root/statefarm/mysfdomain-ui/-/commit/${versionNumber}`"
						:title="`Git Commit SHA ${versionNumber}`"
						class="text-xs font-mono"
					>
						Commit {{ versionNumber.substring(0, 6) }}&hellip;
					</AppLink>
				</div>
			</li>
			<li
				v-for="{ key, title } in tabs"
				:key="key"
				:aria-controls="`tabs-${key}`"
				:aria-selected="key === selected"
				role="tab"
				tabindex="0"
				:class="{
					'border-l-4 border-orange-500 bg-gray-800 hover:bg-gray-900 text-center':
						key === selected,
				}"
				class="cursor-pointer px-4 py-2 uppercase text-gray-100 hover:bg-gray-600 hover:transition-all focus:transition-all"
				@click="setSelected(key)"
				@keydown.enter="setSelected(key)"
			>
				{{ title }}
			</li>
		</ul>

		<div
			v-for="{ key, comp, requiresAgent, title, ...args } in tabs"
			:key="key"
			:class="{
				'h-full w-full p-1 md:p-3 ': key === selected,
			}"
			class="flow-root overflow-y-auto bg-gray-900"
		>
			<template v-if="key === selected">
				<BaseAlert v-if="requiresAgent && !agent" type="warn">
					This control requires an agent. Please navigate to the agent's dashboard first.
				</BaseAlert>
				<component :is="comp" v-else v-bind="args" />
			</template>
		</div>
	</div>
</template>

<script setup>
import { computed, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import useAgentStore from '@/stores/agent/agent';
import useAppVersionStore from '@/stores/appVersion';

import AppLink from '../ui/AppLink.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';

import AgentSearch from '@/components/MirusControls/AgentSearch';
import BillingControls from '@/components/MirusControls/BillingControls';
import NoteList from '@/components/MirusControls/NoteList';
import CallTracking from '@/components/MirusControls/CallTracking';
import MoveDomain from '@/components/MirusControls/MoveDomain';
import EmailSearch from '@/components/MirusControls/EmailSearch';
import Registrar from '@/components/MirusControls/RegistrarList';
import SiteNotifications from '@/components/MirusControls/SiteNotifications';
import SiteConfig from '@/components/MirusControls/SiteConfig';
import GlossaryEditor from '@/components/MirusControls/GlossaryEditor';
import JobListingUrl from '@/components/MirusControls/JobListingUrl.vue';
import ComplianceHelper from '@/components/MirusControls/ComplianceHelper.vue';

const LOCALDEV = import.meta.env.MODE === 'development';

const tabs = [
	{ key: 'search', comp: AgentSearch, title: 'Search', dark: true },
	{ key: 'billing', comp: BillingControls, title: 'Billing', requiresAgent: true },
	{ key: 'calltracking', comp: CallTracking, title: 'Call Tracking', requiresAgent: true },
	{ key: 'compliance_helper', comp: ComplianceHelper, title: 'Compliance Helper' },
	{ key: 'email_search', comp: EmailSearch, title: 'Email Search' },
	{ key: 'glossary', comp: GlossaryEditor, title: 'Glossary' },
	{ key: 'job_listing_url', comp: JobListingUrl, title: 'Jobs Listing', requiresAgent: true },
	{ key: 'registrar', comp: Registrar, title: 'Manage Registrar', requiresAgent: true },
	{ key: 'move_domain', comp: MoveDomain, title: 'Move Domain', requiresAgent: true },
	{ key: 'notes', comp: NoteList, title: 'Notes', requiresAgent: true },
	{ key: 'notifications', comp: SiteNotifications, title: 'Notifications' },
	{ key: 'site_config', comp: SiteConfig, title: 'Site Config' },
];

const router = useRouter();
const route = useRoute();

const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);

const appVersionStore = useAppVersionStore();
const { versionNumber } = storeToRefs(appVersionStore);

const selected = computed(() => route.query.admin || 'search');

function setSelected(value) {
	router.replace({ query: { ...route.query, admin: value } });
}

watchEffect(async () => {
	if (route.params.agentID?.length > 0 && !agent.value) {
		await agentStore.ensureAgentData();
	}
});
</script>

<style lang="scss" module></style>
